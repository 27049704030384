import { useLayoutEffect, useState } from 'react'

function buildThresholdList() {
  let thresholds = []
  let numSteps = 20

  for (let i = 0; i < numSteps; i++) {
    let ratio = i / numSteps
    thresholds.push(ratio)
  }
  thresholds.push(0)
  return thresholds
}

export const useOnScreen = options => {
  const [ref, setRef] = useState(null)
  const [visible, setVisible] = useState(false)
  const [ratio, setRatio] = useState(0.0)

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setVisible(entry.isIntersecting)
        setRatio(entry.intersectionRatio)
      },
      { ...options, threshold: buildThresholdList() }
    )

    if (ref) {
      observer.observe(ref)
    }
    return () => {
      if (ref) {
        observer.unobserve(ref)
      }
    }
  }, [ref, options])

  return { setRef, visible, ratio }
}
