import React, { useEffect, useRef, useState } from 'react'
import tw, { styled } from 'twin.macro'
import theme from 'styled-theming'
import { useOnScreen } from '../hooks/useOnScreen'
import { AnimatePresence, motion } from 'framer-motion'
import { Text } from '../styles/styles'
import { usePages } from '../hooks/usePages'
import { useOnResize } from '../hooks/useOnResize'
import { Bulb } from './Loader'

export const Images = ({ images, index }) => {
  const multi = images?.length > 1
  return (
    <ImagesContainer multi={multi} className="image_container">
      {images.map(({ ref, imgText }, i) => (
        <StickyImage key={`image-${(index, i)}`} reference={ref} imgText={imgText} />
      ))}
    </ImagesContainer>
  )
}

const ImagesContainer = styled.div`
  ${tw`flex flex-col my-8 md:my-20`}
  ${({ multi }) => multi && tw`gap-y-12`}
`
/* *************
 * https://github.com/albertcht/python-gcs-image
 * https://blog.albert-chen.com/resize-images-on-google-cloud-storage/
 *************** */

const StickyImage = ({ _snap, reference, imgText }) => {
  const [url, setUrl] = useState()
  const { visible, setRef } = useOnScreen()
  const { header, fade, footer } = useOnResize()
  const { mounting, fetchImage } = usePages()
  const imageRef = useRef()
  const [maxDim, setMaxDim] = useState()
  const [loading, setLoading] = useState(true)
  const [_retry, setRetry] = useState(false)

  // Wait for app to mount before setting dimensions
  useEffect(() => {
    if (mounting) return
    setMaxDim({
      x: parseInt(window.innerWidth),
      y: parseInt(window.innerHeight - header - footer - 2 * fade),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounting])

  useEffect(() => {
    if (mounting || url || !reference || !maxDim) return
    const getImage = async imageName => {
      setLoading(true)
      try {
        const url = await fetchImage(imageName)
        setUrl(url + `=w${maxDim.x}-h${maxDim.y}`)
        setRetry(false)
      } catch (err) {
        const { missing } = err
        // TODO: use fallback asset
        setUrl('https://picsum.photos/600/400')
        missing && setRetry(true)
      }
    }
    getImage(reference)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, maxDim, url])

  return (
    <Container
      ref={setRef}
      variants={{
        hide: { opacity: 0 },
        show: { opacity: 1 },
      }}
      initial={{ opacity: 0 }}
      animate={visible && 'show'}
      transition={{
        duration: 0.2,
      }}
    >
      <Overlay loading={loading} />
      <ScrollContainer>
        <Image id={reference} src={url} ref={imageRef} onLoad={() => setLoading(false)} />
        {imgText && (
          <ImageText as="div" format={{ type: 'image' }}>
            {imgText}
          </ImageText>
        )}
      </ScrollContainer>
    </Container>
  )
}

const Container = tw(motion.div)`relative w-full h-full minHeight[30vh]
// bg-green-900
`
const ScrollContainer = tw.div`
flex flex-col h-full w-full items-center
//sticky top[15%]
//bg-red-300
`

const Image = tw.img`
// maxHeight[75vh]
// object-contain w-full h-full IF WE WANT TO SCALE UP IMAGES
`
const ImageText = tw(Text)`mx-auto mt-3 md:mt-6`
const Overlay = ({ loading }) => (
  <AnimatePresence>
    {loading && (
      <StyledOverlay
        transition={{ duration: 0.2 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Bulb visible={true} small={true} />
      </StyledOverlay>
    )}
  </AnimatePresence>
)
const StyledOverlay = styled(motion.div)`
  ${tw`absolute w-full h-full flex items-center justify-center z-50 bg-opacity-50`}
  ${theme('mode', {
    light: tw`bg-gray-300`,
    dark: tw`bg-dark-hover`,
  })}
`
