import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { useTheme } from 'styled-components'

const MenuButton = ({
  isOpen = false,
  width = 24,
  height = 24,
  strokeWidth = 1,
  transition = null,
  lineProps = null,
  ...props
}) => {
  const [hover, setHover] = useState(false)
  const variant = isOpen ? 'opened' : 'closed'
  const top = {
    closed: {
      rotate: 0,
      translateY: 0.5,
    },
    opened: {
      rotate: 45,
      translateY: 2,
    },
  }
  const center = {
    closed: {
      opacity: 1,
    },
    opened: {
      opacity: 0,
    },
  }
  const center2 = {
    closed: {
      translateX: 1,
      opacity: 1,
    },
    opened: {
      opacity: 0,
    },
  }
  const bottom = {
    closed: {
      rotate: 0,
      translateY: -0.5,
    },
    opened: {
      rotate: -45,
      translateY: -2,
    },
  }
  const them = useTheme()
  lineProps = {
    stroke: them.mode === 'dark' ? '#FDF9F0' : '#1C1D1E',
    strokeWidth,
    vectorEffect: 'non-scaling-stroke',
    initial: 'closed',
    animate: variant,
    transition,
    ...lineProps,
  }
  const unitHeight = 4
  const unitWidth = (unitHeight * width) / height

  return (
    <motion.svg
      viewBox={`0 0 ${unitWidth} ${unitHeight}`}
      overflow="visible"
      preserveAspectRatio="none"
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
      width={width}
      height={height}
      {...props}
    >
      <motion.line x1="0" x2={unitWidth} y1="0" y2="0" variants={top} {...lineProps} />
      <motion.line
        x1={hover ? '1' : '0'}
        x2={unitWidth}
        y1="2"
        y2="2"
        variants={hover ? center2 : center}
        {...lineProps}
      />
      <motion.line x1="0" x2={unitWidth} y1="4" y2="4" variants={bottom} {...lineProps} />
    </motion.svg>
  )
}

export { MenuButton }
