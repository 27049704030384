import React from 'react'
import tw, { styled } from 'twin.macro'

export const YearSensor = ({ debug, year }) => {
  return (
    <Sensor id={`#year${year}`} debug={debug}>
      YEAR:{JSON.stringify(year)}
    </Sensor>
  )
}
const Sensor = styled.div`
  ${tw`absolute left-20 right-20 flex pointer-events-none bg-red-400 invisible bg-opacity-40 break-all`}
  ${({ debug }) => debug && tw`visible`}
`
