import React from 'react'

// Used
export const DARK_MOON = () => (
  <svg
    viewBox="0 0 24 24"
    width="26"
    height="26"
    stroke="#1C1D1E"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
  </svg>
)

// Used
export const LIGHT_SUN = () => (
  <svg
    viewBox="0 0 24 24"
    width="26"
    height="26"
    stroke="#FDF9F0"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="12" cy="12" r="5"></circle>
    <line x1="12" y1="1" x2="12" y2="3"></line>
    <line x1="12" y1="21" x2="12" y2="23"></line>
    <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
    <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
    <line x1="1" y1="12" x2="3" y2="12"></line>
    <line x1="21" y1="12" x2="23" y2="12"></line>
    <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
    <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
  </svg>
)

export const LIGHT_ZOOM_IN = () => (
  <svg
    viewBox="0 0 24 24"
    width="26"
    height="26"
    stroke="#FDF9F0"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="11" cy="11" r="8"></circle>
    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
    <line x1="11" y1="8" x2="11" y2="14"></line>
    <line x1="8" y1="11" x2="14" y2="11"></line>
  </svg>
)
export const DARK_ZOOM_IN = () => (
  <svg
    viewBox="0 0 24 24"
    width="26"
    height="26"
    stroke="#1C1D1E"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="11" cy="11" r="8"></circle>
    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
    <line x1="11" y1="8" x2="11" y2="14"></line>
    <line x1="8" y1="11" x2="14" y2="11"></line>
  </svg>
)

export const LIGHT_ZOOM_OUT = () => (
  <svg
    viewBox="0 0 24 24"
    width="26"
    height="26"
    stroke="#FDF9F0"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="11" cy="11" r="8"></circle>
    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
    <line x1="8" y1="11" x2="14" y2="11"></line>
  </svg>
)
export const DARK_ZOOM_OUT = () => (
  <svg
    viewBox="0 0 24 24"
    width="26"
    height="26"
    stroke="#1C1D1E"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="11" cy="11" r="8"></circle>
    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
    <line x1="8" y1="11" x2="14" y2="11"></line>
  </svg>
)

export const DARK_A_BIG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 142.77 140.53"
    fill={'#282828'}
  >
    <path
      className="cls-1"
      d="M321.39,320.26h-57.6v-6.93a60.57,60.57,0,0,0,12.64-1.95c3.35-1,5-2.18,5-3.62a16.08,16.08,0,0,0-.19-2.15,10.88,10.88,0,0,0-.59-2.24L269.2,272.31H220.56q-2.75,6.84-4.45,11.92T213,293.6a64.52,64.52,0,0,0-1.76,6.84,24.26,24.26,0,0,0-.49,4.3q0,3.9,6.15,6.05a49.69,49.69,0,0,0,13.87,2.54v6.93H178.61v-6.93a40.77,40.77,0,0,0,6.35-1.12,20.77,20.77,0,0,0,6.25-2.49,25,25,0,0,0,6.06-5.52,35.86,35.86,0,0,0,4.19-8q10.46-26.07,23-58.59T247,179.74h7.82L301,299.27a23.15,23.15,0,0,0,3.32,6.15,22.91,22.91,0,0,0,5.17,4.59,21.56,21.56,0,0,0,5.86,2.29,29.64,29.64,0,0,0,6.06,1Zm-55.75-56.64-21-53.77L224,263.62Z"
      transform="translate(-178.61 -179.74)"
    />
  </svg>
)
export const DARK_A_SMALL = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 142.77 140.53"
    fill={'#282828'}
  >
    <path
      className="cls-1"
      d="M321.39,320.26h-57.6v-6.93a60.57,60.57,0,0,0,12.64-1.95c3.35-1,5-2.18,5-3.62a16.08,16.08,0,0,0-.19-2.15,10.88,10.88,0,0,0-.59-2.24L269.2,272.31H220.56q-2.75,6.84-4.45,11.92T213,293.6a64.52,64.52,0,0,0-1.76,6.84,24.26,24.26,0,0,0-.49,4.3q0,3.9,6.15,6.05a49.69,49.69,0,0,0,13.87,2.54v6.93H178.61v-6.93a40.77,40.77,0,0,0,6.35-1.12,20.77,20.77,0,0,0,6.25-2.49,25,25,0,0,0,6.06-5.52,35.86,35.86,0,0,0,4.19-8q10.46-26.07,23-58.59T247,179.74h7.82L301,299.27a23.15,23.15,0,0,0,3.32,6.15,22.91,22.91,0,0,0,5.17,4.59,21.56,21.56,0,0,0,5.86,2.29,29.64,29.64,0,0,0,6.06,1Zm-55.75-56.64-21-53.77L224,263.62Z"
      transform="translate(-178.61 -179.74)"
    />
  </svg>
)
export const LIGHT_A_BIG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 142.77 140.53"
    fill="#FDF9F0"
  >
    <path
      className="cls-1"
      d="M321.39,320.26h-57.6v-6.93a60.57,60.57,0,0,0,12.64-1.95c3.35-1,5-2.18,5-3.62a16.08,16.08,0,0,0-.19-2.15,10.88,10.88,0,0,0-.59-2.24L269.2,272.31H220.56q-2.75,6.84-4.45,11.92T213,293.6a64.52,64.52,0,0,0-1.76,6.84,24.26,24.26,0,0,0-.49,4.3q0,3.9,6.15,6.05a49.69,49.69,0,0,0,13.87,2.54v6.93H178.61v-6.93a40.77,40.77,0,0,0,6.35-1.12,20.77,20.77,0,0,0,6.25-2.49,25,25,0,0,0,6.06-5.52,35.86,35.86,0,0,0,4.19-8q10.46-26.07,23-58.59T247,179.74h7.82L301,299.27a23.15,23.15,0,0,0,3.32,6.15,22.91,22.91,0,0,0,5.17,4.59,21.56,21.56,0,0,0,5.86,2.29,29.64,29.64,0,0,0,6.06,1Zm-55.75-56.64-21-53.77L224,263.62Z"
      transform="translate(-178.61 -179.74)"
    />
  </svg>
)
export const LIGHT_A_SMALL = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 142.77 140.53"
    fill="#FDF9F0"
  >
    <path
      className="cls-1"
      d="M321.39,320.26h-57.6v-6.93a60.57,60.57,0,0,0,12.64-1.95c3.35-1,5-2.18,5-3.62a16.08,16.08,0,0,0-.19-2.15,10.88,10.88,0,0,0-.59-2.24L269.2,272.31H220.56q-2.75,6.84-4.45,11.92T213,293.6a64.52,64.52,0,0,0-1.76,6.84,24.26,24.26,0,0,0-.49,4.3q0,3.9,6.15,6.05a49.69,49.69,0,0,0,13.87,2.54v6.93H178.61v-6.93a40.77,40.77,0,0,0,6.35-1.12,20.77,20.77,0,0,0,6.25-2.49,25,25,0,0,0,6.06-5.52,35.86,35.86,0,0,0,4.19-8q10.46-26.07,23-58.59T247,179.74h7.82L301,299.27a23.15,23.15,0,0,0,3.32,6.15,22.91,22.91,0,0,0,5.17,4.59,21.56,21.56,0,0,0,5.86,2.29,29.64,29.64,0,0,0,6.06,1Zm-55.75-56.64-21-53.77L224,263.62Z"
      transform="translate(-178.61 -179.74)"
    />
  </svg>
)

export const LIGHT_FLAG = () => (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    stroke="#FDF9F0"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
    <line x1="4" y1="22" x2="4" y2="15"></line>
  </svg>
)
export const DARK_FLAG = () => (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    stroke="#1C1D1E"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
    <line x1="4" y1="22" x2="4" y2="15"></line>
  </svg>
)

export const LIGHT_X_CIRCLE = () => (
  <svg
    viewBox="0 0 24 24"
    width="42"
    height="42"
    stroke="#FDF9F0"
    strokeWidth="1.5"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="css-i6dzq1"
  >
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="15" y1="9" x2="9" y2="15"></line>
    <line x1="9" y1="9" x2="15" y2="15"></line>
  </svg>
)
export const DARK_X_CIRCLE = () => (
  <svg
    viewBox="0 0 24 24"
    width="42"
    height="42"
    stroke="#1C1D1E"
    strokeWidth="1.5"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="css-i6dzq1"
  >
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="15" y1="9" x2="9" y2="15"></line>
    <line x1="9" y1="9" x2="15" y2="15"></line>
  </svg>
)

export const LIGHT_SCROLL = () => (
  <svg width="25" height="40" viewBox="0 0 25 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="23" height="38" rx="11.5" stroke="#FDF9F0" strokeWidth="2" />
  </svg>
)
export const LIGHT_SCROLL_WHEEL = () => (
  <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="2.5" cy="2.5" r="2.5" fill="#FDF9F0" />
  </svg>
)

export const LIGHT_LOADING = () => (
  <svg
    viewBox="0 0 455.15 816.23"
    width="240"
    height="240"
    xmlns="http://www.w3.org/2000/svg"
    fill={'#fdf9f0'}
  >
    <path
      className="cls-1"
      d="M449.41,871.43,462,891.18v16.3h81.38v-16.3L556,871.43Zm16.6,18-11-15.88h5.63l10.89,15.88Zm26.94,0H479.54l-7.42-15.88H493Z"
      transform="translate(-272.93 -91.25)"
    />
    <path
      className="cls-1"
      d="M502.69,844.72H527.8L523,857.05s27.5-.36,35-.36c7.7,0,10.14-9,11.37-12H588.1c-5.33,16.53-25.59,22.71-25.59,22.71H435.58c-20.16-6.62-18.76-31.14-18.76-31.14l168.64-10.75c3.34,8.52,3.15,14.29,3.1,15.18H502.69Z"
      transform="translate(-272.93 -91.25)"
    />
    <polygon
      className="cls-1"
      points="324.83 714.8 297.33 716.5 293.33 716.75 259.76 718.82 255.76 719.07 208.38 722 208.63 726 255.76 723.07 255.76 733.87 144.3 741.37 134.69 730.56 174.78 728.08 174.53 724.09 134.81 726.55 144.3 712.31 255.76 705.01 259.76 704.75 293.33 702.55 297.33 702.28 314.35 701.17 324.83 714.8"
    />
    <polygon
      className="cls-1"
      points="324.63 718.82 313.26 730.05 297.33 731.02 297.33 720.51 324.63 718.82"
    />
    <polygon
      className="cls-1"
      points="324.83 681.56 297.33 683.26 293.33 683.51 259.76 685.58 255.76 685.83 208.38 688.76 208.63 692.75 255.76 689.83 255.76 700.63 144.3 708.13 134.69 697.32 174.78 694.84 174.53 690.85 134.81 693.31 144.3 679.07 255.76 671.77 259.76 671.51 293.33 669.31 297.33 669.04 314.35 667.93 324.83 681.56"
    />
    <polygon
      className="cls-1"
      points="324.63 685.58 313.26 696.81 297.33 697.78 297.33 687.27 324.63 685.58"
    />
    <polygon
      className="cls-1"
      points="324.83 648.38 297.33 650.08 293.33 650.33 259.76 652.4 255.76 652.65 208.38 655.58 208.63 659.57 255.76 656.65 255.76 667.45 144.3 674.95 134.69 664.14 174.78 661.66 174.53 657.67 134.81 660.13 144.3 645.89 255.76 638.59 259.76 638.33 293.33 636.13 297.33 635.86 314.35 634.75 324.83 648.38"
    />
    <polygon
      className="cls-1"
      points="324.63 652.4 313.26 663.63 297.33 664.6 297.33 654.09 324.63 652.4"
    />
    <path className="cls-1" d="M502.69,739.76" transform="translate(-272.93 -91.25)" />
    <path className="cls-1" d="M402.75,795.74" transform="translate(-272.93 -91.25)" />
    <polygon
      className="cls-1"
      points="243.87 634.75 243.87 635.31 144.8 641.79 134.25 634.75 243.87 634.75"
    />
    <polygon
      className="cls-1"
      points="296.48 630.74 296.61 604.2 306.62 604.2 306.62 600.2 325.32 600.2 325.32 630.74 296.48 630.74"
    />
    <path
      className="cls-1"
      d="M404.22,686.45c-9.06,0-19.67-12.52-23.83-19.06-16-25.1-5.68-54.94-10.78-82.43-1.82-9.84-5.55-19.22-9.52-28.4-22.91-53-56.83-101.09-75-156.2C268.57,350,268.66,295,286.62,245a224.37,224.37,0,0,1,23.48-47.25,230.81,230.81,0,0,1,27.16-33.84,252.52,252.52,0,0,1,69.32-50.6,225.46,225.46,0,0,1,38.55-14.87,183.86,183.86,0,0,1,38.74-6.67c17-1,34.18-.77,51,2a204.06,204.06,0,0,1,39.7,10.88,239.38,239.38,0,0,1,38.6,19,253.3,253.3,0,0,1,35.57,26,243.52,243.52,0,0,1,30.63,32.12q6.11,7.74,11.53,16A224.85,224.85,0,0,1,714.38,245c18,50,18,105,1.48,155.34-18.12,55.11-52,103.19-75,156.2-4,9.18-7.69,18.56-9.52,28.4-5.09,27.49,5.2,57.33-10.78,82.43-4.16,6.54-14.76,19.06-23.83,19.06Z"
      transform="translate(-272.93 -91.25)"
    />
    <path
      className="cls-1"
      d="M511.09,695.41v-4H407.14V722H528.23V695.41Zm-92.71,5.25c.75,0,1.24-.28,1.37-.72H421c-.14.93-1.13,1.54-2.65,1.54-1.71,0-2.8-.87-2.8-2.09s1.09-2.09,2.8-2.09c1.52,0,2.51.62,2.65,1.55h-1.28c-.13-.44-.62-.73-1.37-.73-1,0-1.54.5-1.54,1.27S417.4,700.66,418.38,700.66Zm7.66.71h-1.37l-.82-1.1h-.63v1.1H422v-4h2.21c1.09,0,1.87.6,1.87,1.43a1.39,1.39,0,0,1-1,1.27Z"
      transform="translate(-272.93 -91.25)"
    />
    <path
      className="cls-1"
      d="M424.05,698.16h-.83v1.37h.83a.7.7,0,1,0,0-1.37Z"
      transform="translate(-272.93 -91.25)"
    />
  </svg>
)

export const DARK_LOADING = () => (
  <svg
    viewBox="0 0 455.15 816.23"
    width="240"
    height="240"
    xmlns="http://www.w3.org/2000/svg"
    fill={'#282828'}
  >
    <path
      className="cls-1"
      d="M449.41,871.43,462,891.18v16.3h81.38v-16.3L556,871.43Zm16.6,18-11-15.88h5.63l10.89,15.88Zm26.94,0H479.54l-7.42-15.88H493Z"
      transform="translate(-272.93 -91.25)"
    />
    <path
      className="cls-1"
      d="M502.69,844.72H527.8L523,857.05s27.5-.36,35-.36c7.7,0,10.14-9,11.37-12H588.1c-5.33,16.53-25.59,22.71-25.59,22.71H435.58c-20.16-6.62-18.76-31.14-18.76-31.14l168.64-10.75c3.34,8.52,3.15,14.29,3.1,15.18H502.69Z"
      transform="translate(-272.93 -91.25)"
    />
    <polygon
      className="cls-1"
      points="324.83 714.8 297.33 716.5 293.33 716.75 259.76 718.82 255.76 719.07 208.38 722 208.63 726 255.76 723.07 255.76 733.87 144.3 741.37 134.69 730.56 174.78 728.08 174.53 724.09 134.81 726.55 144.3 712.31 255.76 705.01 259.76 704.75 293.33 702.55 297.33 702.28 314.35 701.17 324.83 714.8"
    />
    <polygon
      className="cls-1"
      points="324.63 718.82 313.26 730.05 297.33 731.02 297.33 720.51 324.63 718.82"
    />
    <polygon
      className="cls-1"
      points="324.83 681.56 297.33 683.26 293.33 683.51 259.76 685.58 255.76 685.83 208.38 688.76 208.63 692.75 255.76 689.83 255.76 700.63 144.3 708.13 134.69 697.32 174.78 694.84 174.53 690.85 134.81 693.31 144.3 679.07 255.76 671.77 259.76 671.51 293.33 669.31 297.33 669.04 314.35 667.93 324.83 681.56"
    />
    <polygon
      className="cls-1"
      points="324.63 685.58 313.26 696.81 297.33 697.78 297.33 687.27 324.63 685.58"
    />
    <polygon
      className="cls-1"
      points="324.83 648.38 297.33 650.08 293.33 650.33 259.76 652.4 255.76 652.65 208.38 655.58 208.63 659.57 255.76 656.65 255.76 667.45 144.3 674.95 134.69 664.14 174.78 661.66 174.53 657.67 134.81 660.13 144.3 645.89 255.76 638.59 259.76 638.33 293.33 636.13 297.33 635.86 314.35 634.75 324.83 648.38"
    />
    <polygon
      className="cls-1"
      points="324.63 652.4 313.26 663.63 297.33 664.6 297.33 654.09 324.63 652.4"
    />
    <path className="cls-1" d="M502.69,739.76" transform="translate(-272.93 -91.25)" />
    <path className="cls-1" d="M402.75,795.74" transform="translate(-272.93 -91.25)" />
    <polygon
      className="cls-1"
      points="243.87 634.75 243.87 635.31 144.8 641.79 134.25 634.75 243.87 634.75"
    />
    <polygon
      className="cls-1"
      points="296.48 630.74 296.61 604.2 306.62 604.2 306.62 600.2 325.32 600.2 325.32 630.74 296.48 630.74"
    />
    <path
      className="cls-1"
      d="M404.22,686.45c-9.06,0-19.67-12.52-23.83-19.06-16-25.1-5.68-54.94-10.78-82.43-1.82-9.84-5.55-19.22-9.52-28.4-22.91-53-56.83-101.09-75-156.2C268.57,350,268.66,295,286.62,245a224.37,224.37,0,0,1,23.48-47.25,230.81,230.81,0,0,1,27.16-33.84,252.52,252.52,0,0,1,69.32-50.6,225.46,225.46,0,0,1,38.55-14.87,183.86,183.86,0,0,1,38.74-6.67c17-1,34.18-.77,51,2a204.06,204.06,0,0,1,39.7,10.88,239.38,239.38,0,0,1,38.6,19,253.3,253.3,0,0,1,35.57,26,243.52,243.52,0,0,1,30.63,32.12q6.11,7.74,11.53,16A224.85,224.85,0,0,1,714.38,245c18,50,18,105,1.48,155.34-18.12,55.11-52,103.19-75,156.2-4,9.18-7.69,18.56-9.52,28.4-5.09,27.49,5.2,57.33-10.78,82.43-4.16,6.54-14.76,19.06-23.83,19.06Z"
      transform="translate(-272.93 -91.25)"
    />
    <path
      className="cls-1"
      d="M511.09,695.41v-4H407.14V722H528.23V695.41Zm-92.71,5.25c.75,0,1.24-.28,1.37-.72H421c-.14.93-1.13,1.54-2.65,1.54-1.71,0-2.8-.87-2.8-2.09s1.09-2.09,2.8-2.09c1.52,0,2.51.62,2.65,1.55h-1.28c-.13-.44-.62-.73-1.37-.73-1,0-1.54.5-1.54,1.27S417.4,700.66,418.38,700.66Zm7.66.71h-1.37l-.82-1.1h-.63v1.1H422v-4h2.21c1.09,0,1.87.6,1.87,1.43a1.39,1.39,0,0,1-1,1.27Z"
      transform="translate(-272.93 -91.25)"
    />
    <path
      className="cls-1"
      d="M424.05,698.16h-.83v1.37h.83a.7.7,0,1,0,0-1.37Z"
      transform="translate(-272.93 -91.25)"
    />
  </svg>
)

export const LIGHT_LOADING_SMALL = () => (
  <svg
    viewBox="0 0 455.15 816.23"
    width="120"
    height="120"
    xmlns="http://www.w3.org/2000/svg"
    fill={'#fdf9f0'}
  >
    <path
      className="cls-1"
      d="M449.41,871.43,462,891.18v16.3h81.38v-16.3L556,871.43Zm16.6,18-11-15.88h5.63l10.89,15.88Zm26.94,0H479.54l-7.42-15.88H493Z"
      transform="translate(-272.93 -91.25)"
    />
    <path
      className="cls-1"
      d="M502.69,844.72H527.8L523,857.05s27.5-.36,35-.36c7.7,0,10.14-9,11.37-12H588.1c-5.33,16.53-25.59,22.71-25.59,22.71H435.58c-20.16-6.62-18.76-31.14-18.76-31.14l168.64-10.75c3.34,8.52,3.15,14.29,3.1,15.18H502.69Z"
      transform="translate(-272.93 -91.25)"
    />
    <polygon
      className="cls-1"
      points="324.83 714.8 297.33 716.5 293.33 716.75 259.76 718.82 255.76 719.07 208.38 722 208.63 726 255.76 723.07 255.76 733.87 144.3 741.37 134.69 730.56 174.78 728.08 174.53 724.09 134.81 726.55 144.3 712.31 255.76 705.01 259.76 704.75 293.33 702.55 297.33 702.28 314.35 701.17 324.83 714.8"
    />
    <polygon
      className="cls-1"
      points="324.63 718.82 313.26 730.05 297.33 731.02 297.33 720.51 324.63 718.82"
    />
    <polygon
      className="cls-1"
      points="324.83 681.56 297.33 683.26 293.33 683.51 259.76 685.58 255.76 685.83 208.38 688.76 208.63 692.75 255.76 689.83 255.76 700.63 144.3 708.13 134.69 697.32 174.78 694.84 174.53 690.85 134.81 693.31 144.3 679.07 255.76 671.77 259.76 671.51 293.33 669.31 297.33 669.04 314.35 667.93 324.83 681.56"
    />
    <polygon
      className="cls-1"
      points="324.63 685.58 313.26 696.81 297.33 697.78 297.33 687.27 324.63 685.58"
    />
    <polygon
      className="cls-1"
      points="324.83 648.38 297.33 650.08 293.33 650.33 259.76 652.4 255.76 652.65 208.38 655.58 208.63 659.57 255.76 656.65 255.76 667.45 144.3 674.95 134.69 664.14 174.78 661.66 174.53 657.67 134.81 660.13 144.3 645.89 255.76 638.59 259.76 638.33 293.33 636.13 297.33 635.86 314.35 634.75 324.83 648.38"
    />
    <polygon
      className="cls-1"
      points="324.63 652.4 313.26 663.63 297.33 664.6 297.33 654.09 324.63 652.4"
    />
    <path className="cls-1" d="M502.69,739.76" transform="translate(-272.93 -91.25)" />
    <path className="cls-1" d="M402.75,795.74" transform="translate(-272.93 -91.25)" />
    <polygon
      className="cls-1"
      points="243.87 634.75 243.87 635.31 144.8 641.79 134.25 634.75 243.87 634.75"
    />
    <polygon
      className="cls-1"
      points="296.48 630.74 296.61 604.2 306.62 604.2 306.62 600.2 325.32 600.2 325.32 630.74 296.48 630.74"
    />
    <path
      className="cls-1"
      d="M404.22,686.45c-9.06,0-19.67-12.52-23.83-19.06-16-25.1-5.68-54.94-10.78-82.43-1.82-9.84-5.55-19.22-9.52-28.4-22.91-53-56.83-101.09-75-156.2C268.57,350,268.66,295,286.62,245a224.37,224.37,0,0,1,23.48-47.25,230.81,230.81,0,0,1,27.16-33.84,252.52,252.52,0,0,1,69.32-50.6,225.46,225.46,0,0,1,38.55-14.87,183.86,183.86,0,0,1,38.74-6.67c17-1,34.18-.77,51,2a204.06,204.06,0,0,1,39.7,10.88,239.38,239.38,0,0,1,38.6,19,253.3,253.3,0,0,1,35.57,26,243.52,243.52,0,0,1,30.63,32.12q6.11,7.74,11.53,16A224.85,224.85,0,0,1,714.38,245c18,50,18,105,1.48,155.34-18.12,55.11-52,103.19-75,156.2-4,9.18-7.69,18.56-9.52,28.4-5.09,27.49,5.2,57.33-10.78,82.43-4.16,6.54-14.76,19.06-23.83,19.06Z"
      transform="translate(-272.93 -91.25)"
    />
    <path
      className="cls-1"
      d="M511.09,695.41v-4H407.14V722H528.23V695.41Zm-92.71,5.25c.75,0,1.24-.28,1.37-.72H421c-.14.93-1.13,1.54-2.65,1.54-1.71,0-2.8-.87-2.8-2.09s1.09-2.09,2.8-2.09c1.52,0,2.51.62,2.65,1.55h-1.28c-.13-.44-.62-.73-1.37-.73-1,0-1.54.5-1.54,1.27S417.4,700.66,418.38,700.66Zm7.66.71h-1.37l-.82-1.1h-.63v1.1H422v-4h2.21c1.09,0,1.87.6,1.87,1.43a1.39,1.39,0,0,1-1,1.27Z"
      transform="translate(-272.93 -91.25)"
    />
    <path
      className="cls-1"
      d="M424.05,698.16h-.83v1.37h.83a.7.7,0,1,0,0-1.37Z"
      transform="translate(-272.93 -91.25)"
    />
  </svg>
)

export const DARK_LOADING_SMALL = () => (
  <svg
    viewBox="0 0 455.15 816.23"
    width="120"
    height="120"
    xmlns="http://www.w3.org/2000/svg"
    fill={'#282828'}
  >
    <path
      className="cls-1"
      d="M449.41,871.43,462,891.18v16.3h81.38v-16.3L556,871.43Zm16.6,18-11-15.88h5.63l10.89,15.88Zm26.94,0H479.54l-7.42-15.88H493Z"
      transform="translate(-272.93 -91.25)"
    />
    <path
      className="cls-1"
      d="M502.69,844.72H527.8L523,857.05s27.5-.36,35-.36c7.7,0,10.14-9,11.37-12H588.1c-5.33,16.53-25.59,22.71-25.59,22.71H435.58c-20.16-6.62-18.76-31.14-18.76-31.14l168.64-10.75c3.34,8.52,3.15,14.29,3.1,15.18H502.69Z"
      transform="translate(-272.93 -91.25)"
    />
    <polygon
      className="cls-1"
      points="324.83 714.8 297.33 716.5 293.33 716.75 259.76 718.82 255.76 719.07 208.38 722 208.63 726 255.76 723.07 255.76 733.87 144.3 741.37 134.69 730.56 174.78 728.08 174.53 724.09 134.81 726.55 144.3 712.31 255.76 705.01 259.76 704.75 293.33 702.55 297.33 702.28 314.35 701.17 324.83 714.8"
    />
    <polygon
      className="cls-1"
      points="324.63 718.82 313.26 730.05 297.33 731.02 297.33 720.51 324.63 718.82"
    />
    <polygon
      className="cls-1"
      points="324.83 681.56 297.33 683.26 293.33 683.51 259.76 685.58 255.76 685.83 208.38 688.76 208.63 692.75 255.76 689.83 255.76 700.63 144.3 708.13 134.69 697.32 174.78 694.84 174.53 690.85 134.81 693.31 144.3 679.07 255.76 671.77 259.76 671.51 293.33 669.31 297.33 669.04 314.35 667.93 324.83 681.56"
    />
    <polygon
      className="cls-1"
      points="324.63 685.58 313.26 696.81 297.33 697.78 297.33 687.27 324.63 685.58"
    />
    <polygon
      className="cls-1"
      points="324.83 648.38 297.33 650.08 293.33 650.33 259.76 652.4 255.76 652.65 208.38 655.58 208.63 659.57 255.76 656.65 255.76 667.45 144.3 674.95 134.69 664.14 174.78 661.66 174.53 657.67 134.81 660.13 144.3 645.89 255.76 638.59 259.76 638.33 293.33 636.13 297.33 635.86 314.35 634.75 324.83 648.38"
    />
    <polygon
      className="cls-1"
      points="324.63 652.4 313.26 663.63 297.33 664.6 297.33 654.09 324.63 652.4"
    />
    <path className="cls-1" d="M502.69,739.76" transform="translate(-272.93 -91.25)" />
    <path className="cls-1" d="M402.75,795.74" transform="translate(-272.93 -91.25)" />
    <polygon
      className="cls-1"
      points="243.87 634.75 243.87 635.31 144.8 641.79 134.25 634.75 243.87 634.75"
    />
    <polygon
      className="cls-1"
      points="296.48 630.74 296.61 604.2 306.62 604.2 306.62 600.2 325.32 600.2 325.32 630.74 296.48 630.74"
    />
    <path
      className="cls-1"
      d="M404.22,686.45c-9.06,0-19.67-12.52-23.83-19.06-16-25.1-5.68-54.94-10.78-82.43-1.82-9.84-5.55-19.22-9.52-28.4-22.91-53-56.83-101.09-75-156.2C268.57,350,268.66,295,286.62,245a224.37,224.37,0,0,1,23.48-47.25,230.81,230.81,0,0,1,27.16-33.84,252.52,252.52,0,0,1,69.32-50.6,225.46,225.46,0,0,1,38.55-14.87,183.86,183.86,0,0,1,38.74-6.67c17-1,34.18-.77,51,2a204.06,204.06,0,0,1,39.7,10.88,239.38,239.38,0,0,1,38.6,19,253.3,253.3,0,0,1,35.57,26,243.52,243.52,0,0,1,30.63,32.12q6.11,7.74,11.53,16A224.85,224.85,0,0,1,714.38,245c18,50,18,105,1.48,155.34-18.12,55.11-52,103.19-75,156.2-4,9.18-7.69,18.56-9.52,28.4-5.09,27.49,5.2,57.33-10.78,82.43-4.16,6.54-14.76,19.06-23.83,19.06Z"
      transform="translate(-272.93 -91.25)"
    />
    <path
      className="cls-1"
      d="M511.09,695.41v-4H407.14V722H528.23V695.41Zm-92.71,5.25c.75,0,1.24-.28,1.37-.72H421c-.14.93-1.13,1.54-2.65,1.54-1.71,0-2.8-.87-2.8-2.09s1.09-2.09,2.8-2.09c1.52,0,2.51.62,2.65,1.55h-1.28c-.13-.44-.62-.73-1.37-.73-1,0-1.54.5-1.54,1.27S417.4,700.66,418.38,700.66Zm7.66.71h-1.37l-.82-1.1h-.63v1.1H422v-4h2.21c1.09,0,1.87.6,1.87,1.43a1.39,1.39,0,0,1-1,1.27Z"
      transform="translate(-272.93 -91.25)"
    />
    <path
      className="cls-1"
      d="M424.05,698.16h-.83v1.37h.83a.7.7,0,1,0,0-1.37Z"
      transform="translate(-272.93 -91.25)"
    />
  </svg>
)
