import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import { motion } from 'framer-motion'
import { usePages } from '../hooks/usePages'

export const AudioPlayer = () => {
  const { activeTrack, STOP_MUSIC, scrolling } = usePages()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(activeTrack !== STOP_MUSIC)
  }, [activeTrack, STOP_MUSIC])

  return (
    <AudioContainer
      animate={visible ? 'show' : 'hide'}
      initial={{ y: 200 }}
      transition={{ ease: 'easeOut', duration: 0.3 }}
      variants={{ show: { y: 0 }, hide: { y: 200 } }}
    >
      {visible && activeTrack && !scrolling && (
        <IframeContainer>
          <Iframe
            src={`https://open.spotify.com/embed/track/${activeTrack}`}
            frameBorder="0"
            height="75px"
            allowtransparency="true"
            allow="encrypted-media"
          ></Iframe>
        </IframeContainer>
      )}
    </AudioContainer>
  )
}

const AudioContainer = tw(
  motion.div
)`zIndex[60] rounded-md overflow-hidden w-full maxWidth[340px] ml-4`

const Iframe = tw.iframe`block w-full`
const IframeContainer = tw.div`flex overflow-hidden rounded-md w-full`
