import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import theme from 'styled-theming'
import tw, { styled } from 'twin.macro'
import {
  DARK_LOADING,
  DARK_LOADING_SMALL,
  LIGHT_LOADING,
  LIGHT_LOADING_SMALL,
} from '../styles/icons'
import { usePages } from '../hooks/usePages'
import { useTheme } from 'styled-components'

export const Loader = () => {
  const { loading } = usePages()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    !loading && setIsOpen(false)
  }, [loading])

  return (
    <Overlay
      variants={{
        hide: { opacity: 0 },
        show: { opacity: 1 },
      }}
      transition={{ duration: 0.8 }}
      initial="show"
      animate={loading ? 'show' : 'hide'}
      exit="hide"
      onAnimationComplete={() => setIsOpen(true)}
    >
      <Bulb visible={isOpen} />
    </Overlay>
  )
}

const Overlay = styled(motion.div)`
  ${tw`fixed top-0 bottom-0 w-full h-full flex justify-center items-center zIndex[90] pointer-events-none`}
  ${theme('mode', {
    light: tw`bg-light`,
    dark: tw`bg-dark`,
  })}
`
export const Bulb = ({ visible = false, small = false }) => {
  const theme = useTheme()
  return (
    <BulbContainer>
      <motion.div
        variants={{
          hide: { scale: 0.5, opacity: 0 },
          grow: { scale: 1, opacity: [0, 1], transition: { duration: 0.8 } },
          bob: {
            scale: [1, 1.1],
            opacity: [0, 1],
            transition: {
              repeat: Infinity,
              repeatType: 'reverse',
              repeatDelay: 0,
              duration: 1,
            },
          },
        }}
        initial="hide"
        animate={visible ? 'bob' : 'grow'}
        exit="hide"
      >
        {theme.mode === 'light' ? (
          small ? (
            <DARK_LOADING_SMALL />
          ) : (
            <DARK_LOADING />
          )
        ) : small ? (
          <LIGHT_LOADING_SMALL />
        ) : (
          <LIGHT_LOADING />
        )}
      </motion.div>
    </BulbContainer>
  )
}
const BulbContainer = tw.div`transform[scale(0.6)] xs:transform[scale(0.8)] sm:transform[scale(1)]`
