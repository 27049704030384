import React, { useEffect, useMemo } from 'react'
import theming from 'styled-theming'
import tw, { styled, css } from 'twin.macro'
import { useOnScreen } from '../hooks/useOnScreen'
import { usePages } from '../hooks/usePages'
import { Linebreak, PageFade, Text } from '../styles/styles'
import { MoreComponent } from './MoreComponent'
import { PoemComponent } from './PoemComponent'
import { AudioSensor } from './AudioSensor'
import { useScroll } from '../hooks/useScroll'
import { YearSensor } from './YearSensor'
import { Images } from './ImagesComponent'
import { VideoComponent } from './VideoComponent'
import { motion, useAnimation } from 'framer-motion'
import { Player } from '@lottiefiles/react-lottie-player'
import light_squiggles from '../assets/light-pattern'
import dark_squiggles from '../assets/dark-pattern'
import { Loader } from './Loader'
import { DARK_LOADING_SMALL, LIGHT_LOADING_SMALL } from '../styles/icons'
import { useTheme } from 'styled-components'

const debug = false

export const Reader = () => {
  const {
    pages,
    updateCurrentPage,
    fetchAroundPage,
    fetching = true,
    loading,
    scrolling,
    FETCH_LIMIT,
  } = usePages()
  const { scrollingDown } = useScroll()
  const center = false

  const fetchNextPage = page => {
    if (loading || scrolling || scrollingDown === null) return
    // Ignore fetching again
    if (pages[scrollingDown ? page + FETCH_LIMIT : page - FETCH_LIMIT]?.loaded) return
    fetchAroundPage({ page })
  }

  const controls = useAnimation()
  controls.start({
    opacity: 0,
    transition: { duration: 0.5 },
  })

  return (
    <>
      <Loader />

      {pages.map(pageData => (
        <Page
          key={`page${pageData.pageNo}`}
          pageNo={pageData.pageNo}
          setActivePage={updateCurrentPage}
          fetchNextPage={fetchNextPage}
          pageData={pageData}
          center={center}
        >
          {debug && <Info>{`page ${pageData.pageNo + 1} (page-${pageData.pageNo}.json)`}</Info>}
        </Page>
      ))}

      <FetchingMessage
        id="loading"
        variants={{ show: { y: 0 }, hide: { y: 100 } }}
        initial="hide"
        animate={fetching ? 'show' : 'hide'}
        exit="hide"
      >
        <PageFade position="bottom" />
        Hämtar fler sidor ...
      </FetchingMessage>
    </>
  )
}

const FetchingMessage = tw(
  motion.div
)`relative flex items-center h-32 w-full text-3xl text-gray-600 italic justify-center p-8`

// PAGE

const Page = ({ pageNo, setActivePage, fetchNextPage, pageData, center, children }) => {
  const theme = useTheme()
  const { data, loaded, type } = pageData
  const { setRef: activeRef, visible: active } = useOnScreen({ rootMargin: '-50% 0%' })
  const { setRef: visibleRef, visible } = useOnScreen()

  useEffect(() => {
    active && setActivePage(pageNo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  useEffect(() => {
    // todo determine which direction to fetch
    visible && fetchNextPage(pageNo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, pageNo])

  const parsePageData = ({ items, center, alteration }) => {
    // segment = {format, text}
    const renderSegment = (segment, center = false, i) => (
      <Segment key={'item-' + i} center={center}>
        {segment.map(({ format: { color, ...rest } = {}, text, linebreak }, k) => {
          if (linebreak) {
            return k === 0 ? (
              <Text key={`linebreak-${(i, k)}`}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
            ) : (
              <Text key={`linebreak-${(i, k)}`}>&nbsp;</Text>
            )
          }
          if (text === '&indent') {
            return (
              <span key={`indent-${(i, k)}`}>
                <Text>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
              </span>
            )
          }
          return (
            <Text key={`segment-${(i, k)}`} {...rest} alteration={alteration}>
              {text}
            </Text>
          )
        })}
      </Segment>
    )
    // image = {ref, imageText, snap}
    const renderImage = (images, i) => (
      <Images key={`imageContainer-${i}`} images={images} index={i} />
    )

    // video = {url}
    const renderVideo = (video, i) => <VideoComponent video={video} key={`video-${i}`} />
    // Audio
    const renderAudio = (audio, i) => <AudioSensor key={`audio-${i}`} audio={audio} debug={debug} />

    // poem = [...]
    const renderPoem = (poem, i) => (
      <PoemComponent key={`poem-${i}`}>
        {parsePageData({ items: poem, center: true, alteration: 'poem' })}
      </PoemComponent>
    )
    // more = [...]
    const renderMore = (more, i) => (
      <MoreComponent key={`more-${i}`} page={pageNo} id={`more-${i}`}>
        {parsePageData({ items: more, center: false, alteration: 'more' })}
      </MoreComponent>
    )

    const renderYear = year => {
      return <YearSensor key={`year-${year}`} year={year} debug={debug} />
    }

    return items.map(
      (
        {
          asterisk,
          audio,
          images,
          indent,
          linebreak,
          more,
          poem,
          start_center,
          stop_center,
          segment,
          video,
          year,
        },
        index
      ) => {
        if (start_center) center = true
        if (stop_center) center = false

        if (asterisk) {
          return (
            <div tw="text-center" key={`asterisk-${index}`}>
              <Text>&lowast;</Text>
            </div>
          )
        }

        if (linebreak || indent) {
          return <Linebreak key={`linebreak-${index}`}>&nbsp;</Linebreak>
        }
        if (indent) {
          return <Linebreak key={`indent-${index}`}>&nbsp;</Linebreak>
        }
        if (audio) {
          return renderAudio(audio, index)
        }
        if (images?.length) {
          return renderImage(images, index)
        }
        if (more) {
          return renderMore(more, index)
        }
        if (poem) {
          return renderPoem(poem, index)
        }
        if (segment?.length) {
          return renderSegment(segment, center, index)
        }
        if (video) {
          return renderVideo(video, index)
        }
        if (year) {
          return renderYear(year)
        }
      }
    )
  }

  // Wrapper for title page
  const RenderTitlePage = () => (
    <>
      <div tw="absolute top-0 w-full h-screen overflow-hidden">
        <motion.div
          tw="absolute top-0 w-full h-full opacity-5"
          // initial={{ scale: 1 }}
          // animate={{ scale: 1.05 }}
          // transition={{
          //   repeat: Infinity,
          //   repeatType: 'reverse',
          //   repeatDelay: 1,
          //   duration: 10,
          // }}
        >
          <Player
            // autoplay
            // loop
            controls={false}
            // renderer="svg"
            rendererSettings={{
              preserveAspectRatio: 'xMidYMid slice',
            }}
            src={theme.mode === 'dark' ? light_squiggles : dark_squiggles}
            style={{ width: '100vw', height: '100vh' }}
          ></Player>
        </motion.div>
        {/* <div tw="absolute bottom[10%] flex w-full justify-center">
            <ScrollHelper dark={theme.mode === 'dark'} />
          </div> */}
      </div>
      <PageContainer ref={activeRef} loaded={loaded} type={type}>
        {children}
        {!!data.length && parsePageData({ items: data, center })}
      </PageContainer>
    </>
  )

  const RenderEmptyPage = () => {
    return (
      <EmptyPage>
        <StyledOverlay transition={{ duration: 0.2 }} exit={{ opacity: 0 }}>
          {theme.mode === 'light' ? <DARK_LOADING_SMALL /> : <LIGHT_LOADING_SMALL />}
        </StyledOverlay>
      </EmptyPage>
    )
  }

  return useMemo(
    () => (
      <>
        <div ref={visibleRef} id={`#page${pageNo}`} />
        <div ref={activeRef}>
          {pageNo === 0 ? (
            <RenderTitlePage />
          ) : data.length ? (
            <PageContainer loaded={loaded} type={type} id={`#page${pageNo}_container`}>
              {children}
              {parsePageData({ items: data, center })}
            </PageContainer>
          ) : (
            <RenderEmptyPage />
          )}
        </div>
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeRef, data, loaded, theme]
  )
}

const PageContainer = styled.div`
  ${tw`relative w-screen font-serif px-8 xs:px-12 sm:px-16 md:px-28 lg:px-36 xl:px-48 2xl:px-72`}
  ${({ type }) => css`
    ${type === 'title' && tw`text-left justify-center min-h-screen`};
    ${type === 'fullPage' && tw`min-h-screen`};
  `}
`
const Info = tw.div`text-gray-900 left-0 m-2 p-1 bg-blue-300 z-10 h-8 w-full`
const Segment = styled.div`
  ${({ center }) => center && tw`text-center`}
`
const EmptyPage = tw(PageContainer)`minHeight[80vh] items-center justify-center`

const StyledOverlay = styled(motion.div)`
  ${tw`absolute top-3 left-3 right-3 bottom-3 overflow-hidden flex items-center justify-center`}
  ${theming('mode', {
    light: tw`bg-gray-200`,
    dark: tw`bg-dark-hover`,
  })}
`
