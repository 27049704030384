import theme from 'styled-theming'
import tw, { styled, css } from 'twin.macro'
import { createGlobalStyle } from 'styled-components'
import { motion } from 'framer-motion'

const getBackground = theme('mode', {
  light: '#FDF9F0',
  dark: '#1C1D1E',
})

const getForeground = theme('mode', {
  light: '#1C1D1E',
  dark: '#FDF9F0',
})

const GlobalStyle = createGlobalStyle`

  body {
    font-family: Georgia;
    background-color: ${getBackground};
    color: ${getForeground};
    &::-webkit-scrollbar {
      display: none;
    }
    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  }
  body.lock-screen {
    overflow: hidden;
  }

  .header, .footer, .modal, .loading {
    background-color: ${getBackground};
    color: ${getForeground};
  }

  .page, .more {
    -webkit-transition: font-size .2s ease;
    -moz-transition: font-size .2s ease;
    -o-transition: font-size .2s ease;
    transition: font-size .2s ease;
  }

  
  br {
    line-height: 150%;
    background: red;
    padding: 1rem;
 }
  `
export default GlobalStyle

export const Button = styled.div`
  ${tw`font-serif font-light px-5 py-3 rounded-md w-min whitespace-nowrap cursor-pointer border-2 select-none`}
  ${theme('mode', {
    light: tw`border-gray-500 hover:bg-gray-300 active:bg-gray-400`,
    dark: tw`border-gray-400 hover:bg-dark-hover active:bg-dark-active`,
  })}
`
export const PageFade = styled(motion.div)`
  ${tw`w-full absolute left-0 right-0 z-50`};
  pointer-events: none;
  ${({ position }) =>
    theme('mode', {
      light: css`
        ${position === 'top'
          ? css`
              ${tw`-bottom-12 h-12`};
              background-image: linear-gradient(0, rgba(253, 249, 240, 0), rgba(253, 249, 240, 1));
            `
          : css`
              ${tw`-top-12 h-12`};
              background-image: linear-gradient(0, rgba(253, 249, 240, 1), rgba(253, 249, 240, 0));
            `}
      `,
      dark: css`
        ${position === 'top'
          ? css`
              ${tw`-bottom-12 h-12`};
              background-image: linear-gradient(0, rgba(28, 29, 30, 0), rgba(28, 29, 30, 1));
            `
          : css`
              ${tw`-top-12 h-12`};
              background-image: linear-gradient(0, rgba(28, 29, 30, 1), rgba(28, 29, 30, 0));
            `}
      `,
    })};
`

// --------------- Text
export const textTypes = {
  TITLE: 'title',
  SUBTITLE: 'subtitle',
  HEADER: 'header',
  BIG: 'big',
  BASE: 'base',
  COMPRESSED: 'compressed',
  MORE: 'more',
  IMAGE: 'image',
}

export const mapTextType = ({ size, type }) => {
  const mapBySize = size => {
    switch (size) {
      case '96': {
        return textTypes.TITLE
      }
      case '40': {
        return textTypes.SUBTITLE
      }
      case '32': {
        return textTypes.HEADER
      }
      case '28': {
        return textTypes.BIG
      }
      case '20': {
        return textTypes.COMPRESSED
      }
      default: {
        return textTypes.BASE
      }
    }
  }

  const textType = type ?? mapBySize(size)

  switch (textType) {
    // Titel
    case 'title': {
      return tw`text-5xl sm:text-6xl md:text-7xl lg:text-8xl lg:lineHeight[6rem]`
    }
    // Rolf Börjlind
    case 'subtitle': {
      return tw`text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:lineHeight[6rem]`
    }
    // Förord
    case 'big': {
      return tw`text-2xl md:text-3xl xl:text-4xl`
    }
    // Bildtext
    case 'image': {
      return css`
        ${tw`italic mt-6 text-base md:text-lg xl:text-2xl`}
        ${theme('mode', {
          light: tw`text-gray-700`,
          dark: tw`text-gray-400`,
        })}
      `
    }
    // Prolog
    case 'header': {
      return theme('textZoom', {
        xsmall: tw`text-3xl md:text-3xl xl:text-4xl`,
        small: tw`text-3xl md:text-3xl xl:text-4xl`,
        normal: tw`text-4xl md:text-5xl xl:text-5xl`,
        large: tw`text-4xl md:text-5xl xl:text-5xl`,
        xlarge: tw`text-5xl md:text-5xl xl:text-6xl`,
        xxlarge: tw`text-5xl md:text-6xl xl:text-6xl`,
      })
    }
    // Compressed
    case 'compressed': {
      return theme('textZoom', {
        normal: tw`text-sm md:text-base xl:text-lg`,
      })
    }
    // Allmän text
    case 'base': {
      return css`
        ${theme('textZoom', {
          xsmall: tw`text-xs leading-3 md:text-sm md:leading-5 xl:text-base xl:leading-7`,
          small: tw`text-sm leading-7 md:text-base md:leading-8 xl:text-lg xl:leading-9`,
          // normal: tw`text-base lineHeight[2.1] md:text-xl md:lineHeight[2.2] xl:text-2xl xl:lineHeight[2.35]`,
          normal: tw`text-base lineHeight[1.9em] md:text-xl md:lineHeight[2.0em] xl:text-2xl xl:lineHeight[2.1em]`,
          large: tw`text-xl leading-9 md:text-2xl md:leading-10 xl:text-3xl xl:leading-12`,
          xlarge: tw`text-2xl leading-10 md:text-3xl md:leading-12 xl:text-4xl xl:leading-16`,
          xxlarge: tw`text-3xl leading-12 md:text-4xl md:leading-16 xl:text-5xl xl:leading-18`,
        })}
      `
    }
  }
}

const alterText = type => {
  switch (type) {
    case 'more': {
      // do nothing
      break
    }
    case 'poem': {
      return tw`text-center`
    }
  }
}

export const Text = styled.span`
  ${tw`whitespace-pre-wrap`}
  ${({ alteration, ...format }) => {
    const { bold = false, italic = false } = format
    return css`
      ${bold ? tw`font-semibold` : tw`font-light`};
      ${italic && tw`italic`};
      ${mapTextType(format)};
      ${alteration && alterText(alteration)}
    `
  }}
`

export const Linebreak = styled.div``
