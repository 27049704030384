import React from 'react'
import tw, { styled } from 'twin.macro'

export const PoemComponent = ({ children }) => {
  return (
    <Container>
      <Frame>{children}</Frame>
    </Container>
  )
}
const Container = styled.div`
  ${tw`relative flex justify-center 
  // my-10 md:my-24 xl:my-32
  `}
`

const Frame = tw.div`flex flex-col items-center
// p-4
`
