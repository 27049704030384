import React, { useEffect, useRef, useState } from 'react'
import tw, { styled } from 'twin.macro'
import theme from 'styled-theming'
import { useOnScreen } from '../hooks/useOnScreen'
import { storageRef } from '../firebase'
import { AnimatePresence, motion } from 'framer-motion'
import { Bulb } from './Loader'

export const VideoComponent = ({ video }) => {
  const { visible, setRef } = useOnScreen()
  const containerRef = useRef(null)
  const [loading, setLoading] = useState(!video.url)
  const [mounted, setMounted] = useState(false)
  const [videoUrl, setVideoUrl] = useState(video.url)
  const { ref } = video

  useEffect(() => {
    if (!ref) return
    const getAndLoadHttpUrl = async ref => {
      if (!mounted) return
      const videoRef = storageRef.child(`/videos/${ref}`)
      videoRef
        .getDownloadURL()
        .then(url => {
          setVideoUrl(url)
          setLoading(false)
        })
        .catch(error => {
          console.log({ error })
        })
    }
    setMounted(true)
    getAndLoadHttpUrl(ref)
    return () => setMounted(false)
  }, [ref, mounted])

  return (
    <>
      <div ref={setRef}>
        <Container
          variants={{
            hide: { opacity: 0 },
            show: { opacity: 1 },
          }}
          initial={{ opacity: 0 }}
          animate={visible && 'show'}
          transition={{
            duration: 0.2,
          }}
          ref={containerRef}
        >
          <Overlay loading={loading} />
          {video.url ? (
            <Video>
              <iframe
                loading="lazy"
                src={video.url}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Video>
          ) : (
            videoUrl && (
              <Video>
                <video controls>
                  <source src={videoUrl} />
                </video>
              </Video>
            )
          )}
        </Container>
      </div>
    </>
  )
}

const Container = tw(motion.div)`my-8 md:my-20 w-full`

const Video = styled.div`
  ${tw`relative w-full aspect-ratio[16/9]`};
  & > iframe {
    ${tw`absolute left-0 top-0 h-full w-full`}
  }
  & > video {
    ${tw`absolute left-0 top-0 h-full w-full`}
  }
`

const Overlay = ({ loading }) => (
  <AnimatePresence>
    {loading && (
      <StyledOverlay
        transition={{ duration: 0.2 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Bulb visible={true} small={true} />
      </StyledOverlay>
    )}
  </AnimatePresence>
)
const StyledOverlay = styled(motion.div)`
  ${tw`absolute w-full h-full flex items-center justify-center z-50 bg-opacity-50`}
  ${theme('mode', {
    light: tw`bg-gray-300`,
    dark: tw`bg-dark-hover`,
  })}
`
