import axios from 'axios'
import React, { createContext, useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { useLocation } from 'react-router'
import { analytics } from '../firebase'
import { actionTypes, pageReducer } from '../reducers/pageReducer'
import { useScroll } from './useScroll'

export const PageContext = createContext()
const STOP_MUSIC = '7hMPR0V7cC0hln0SN2afLL'
const NUM_PAGES = 276
const FETCH_LIMIT = 2
const CHAPTERS = [
  // 0-index (pageNo 13 -> page14.json, 163.json->pageNo 162 etc)
  // IF THESE DON'T MATCH SCROLLING WILL NOT WORK!
  { pageNo: 14 - 1, year: '1960-1970', end: 44 - 1 },
  { pageNo: 44 - 1, year: '1970-1980', end: 144 - 1 },
  { pageNo: 144 - 1, year: '1980-1990', end: 184 - 1 },
  { pageNo: 184 - 1, year: '1990-2000', end: 238 - 1 },
  { pageNo: 238 - 1, year: '2000-2010', end: 257 - 1 },
  { pageNo: 257 - 1, year: '2010-2021', end: NUM_PAGES },
]

export const PageProvider = props => {
  let location = useLocation()

  const {
    jumpToPageElement,
    jumpToInnerElement,
    scrollToPageElement,
    scrollToInnerElement,
  } = useScroll()
  const [currentPage, setCurrentPage] = useState(
    () => parseInt(localStorage.getItem('currentPage')) || 0
  )
  const currentYear = useMemo(
    () => CHAPTERS.find(({ pageNo, end }) => currentPage >= pageNo && currentPage < end)?.year,
    [currentPage]
  )
  const [mounting, setMounting] = useState(true)
  const [loading, setLoading] = useState(true)
  const [fetching, setFetching] = useState(false)
  const [scrolling, setScrolling] = useState(false)
  const showEdges = useMemo(() => !!currentPage, [currentPage])
  const [chaptersOpen, setChaptersOpen] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [pages, p_dispatch] = useReducer(pageReducer, [])
  const [activeTrack, setActiveTrack] = useState(STOP_MUSIC)
  const dispatch = {
    addPage: page => p_dispatch({ type: actionTypes.page.add, page }),
    addPages: pages => p_dispatch({ type: actionTypes.page.addMany, pages }),
    fillPages: pages => p_dispatch({ type: actionTypes.page.fill, pages }),
    fillAll: pages => p_dispatch({ type: actionTypes.page.fillPages, pages }),
  }

  // On Mount
  useEffect(() => {
    const initPages = () => {
      dispatch.addPages(
        Array.from(Array(NUM_PAGES)).map((_, i) => ({ pageNo: i, loaded: false, data: '' }))
      )
    }
    // Initiate skeleton pages
    initPages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (scrolling) {
      setActiveTrack(STOP_MUSIC)
    }
  }, [scrolling])

  useEffect(() => {
    const getStartingPages = async startPage => {
      lockPage(true)
      window.scrollTo(0, 0)
      setScrolling(true)
      try {
        await fetchAroundPage({ page: 0, limit: startPage ? 0 : FETCH_LIMIT })
        // Dramatic pause
        if (startPage) {
          await fetchAroundPage({ page: startPage, navScroll: true })
          await jumpToPage({
            page: startPage,
            duration: 2000,
          })
        } else {
          setLoading(false)
        }
      } catch (err) {
        console.log({ err })
      } finally {
        setScrolling(false)
        setLoading(false)
        setMounting(false)
        lockPage(false)
      }
    }
    // Reset the scroll position
    if (pages.length) {
      window.scrollTo(0, 0)
      setTimeout(() => {
        getStartingPages(currentPage)
      }, 200)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages.length])

  // On Url Paste
  useEffect(() => {
    if (location) {
      const page = parseInt(location.hash?.split('#page')[1])
      if (page === currentPage) return
      if (page && page >= 0 && page < NUM_PAGES) {
        scrollToPage({ page })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const lockPage = useCallback((lock = false) => {
    if (lock) document.body.className += 'lock-screen'
    else document.body.classList.remove('lock-screen')
  }, [])

  const jumpToPage = async ({ page, duration = 2000 }) => {
    setLoading(true)
    setScrolling(true)
    try {
      await fetchAroundPage({ page, navScroll: true })
      await jumpToPageElement({
        page: `#page${page}`,
        duration,
      })
      return Promise.resolve()
    } catch (err) {
      console.log({ err })
      return Promise.reject()
    } finally {
      setScrolling(false)
      setLoading(false)
    }
  }

  const scrollToPage = async ({ page, duration = 2000 }) => {
    setScrolling(true)
    try {
      await fetchAroundPage({ page, navScroll: true })

      await scrollToPageElement({
        page: `#page${page}`,
        duration,
      })
      return Promise.resolve()
    } catch (err) {
      console.log({ err })
      return Promise.reject()
    } finally {
      setScrolling(false)
    }
  }

  const jumpToYear = async ({ year, page, duration = 2000 }) => {
    setLoading(true)
    setScrolling(true)
    const yearElement = document.getElementById(`#year${year}`)
    try {
      // If this hasn't been rendered yet
      if (!yearElement) {
        // Fetch those pages first
        // console.log('Go fish')
        await fetchAroundPage({ page, navScroll: true })
        await jumpToPageElement({
          page: `#page${page}`,
          duration: 1000,
        })
        await jumpToInnerElement({
          page: `#page${page}`,
          inner: `#year${year}`,
          duration: 1000,
        })
        return Promise.resolve()
      } else {
        await jumpToInnerElement({
          page: `#page${page}`,
          inner: `#year${year}`,
          duration,
        })
        return Promise.resolve()
      }
    } catch (err) {
      console.log(err)
      return Promise.reject()
    } finally {
      setScrolling(false)
      setLoading(false)
      setCurrentPage(page)
    }
  }

  const scrollToYear = async ({ year, page, duration = 1000 }) => {
    setScrolling(true)
    setLoading(true)
    // setCurrentYear(year)
    const yearElement = document.getElementById(`#year${year}`)
    try {
      // If this hasn't been rendered yet
      if (!yearElement) {
        // Fetch those pages first
        console.error('Fishing for year')
        await fetchAroundPage({ page, navScroll: true })
        await scrollToInnerElement({
          page: `#page${page}`,
          inner: `#year${year}`,
          duration,
        })
        return Promise.resolve()
      } else {
        await scrollToInnerElement({
          page: `#page${page}`,
          inner: `#year${year}`,
          duration,
        })
        return Promise.resolve()
      }
    } catch (err) {
      console.error({ err })
      return Promise.reject()
    } finally {
      setScrolling(false)
      setLoading(false)
    }
  }

  // Stores page where user is looking
  const updateCurrentPage = activePage => {
    if (loading) return
    setCurrentPage(activePage)
    localStorage.setItem('currentPage', JSON.stringify(activePage))
  }

  // Fetch pages around pageNr
  const fetchAroundPage = async ({ page, navScroll = false, limit = FETCH_LIMIT }) => {
    if (fetching) return
    // Don't fetch when pages are on screen during navScroll
    if (scrolling && !navScroll) return

    setFetching(true)
    try {
      // console.log('fetching around ', page)
      const newPages = await fetch(page, limit, loading)
      // Fill the skeletons
      // console.log({ newPages })
      dispatch.fillPages(newPages)
      return Promise.resolve()
    } catch (err) {
      console.error({ err })
      return Promise.reject()
    } finally {
      setFetching(false)
    }
  }

  // Firebase mock
  const importPage = pageNr =>
    import(`../assets/pages/page-${pageNr + 1}.json`).then(module => module.default)

  const fetch = async (aroundPage, limit) => {
    const _timeout = delay => new Promise(resolve => setTimeout(resolve, delay))
    let newPages = []

    // Fetch forwards LIMIT times
    for (let i = aroundPage - limit; i <= aroundPage + limit; i++) {
      let pageNo = i
      // Continue if page doesn't exist
      if (i < 0) {
        // console.log(pageNo + 'doesnt exist')
        continue
      }
      // Break if the page was already loaded
      if (pages[pageNo]?.loaded) {
        // console.log(pageNo + 'loaded already')
        continue
      }
      // Break if the page doesn't exists
      if (pageNo > NUM_PAGES) break

      // console.log('fetching ', pageNo)
      const page = await importPage(pageNo)
        .then(data => {
          const page = { pageNo, data }
          if (pageNo === 0) {
            return { ...page, type: 'title' } // title
          }
          if (pageNo === 1) {
            return { ...page, type: 'fullPage' } // pre-poem
          }
          if (pageNo === NUM_PAGES - 1) {
            return { ...page, type: 'fullPage' } // last page
          }
          return { ...page, type: 'page' }
        })
        .catch(() => {
          // Out of pages
          return null
        })

      page && newPages.push(page)
    }
    // await _timeout(500)
    return Promise.resolve(newPages)
  }

  // Call AppEngine Proxy
  const fetchImage = async imageName => {
    return await axios
      .get(
        `https://rolf-780ff.appspot.com/image-url?bucket=rolf-780ff.appspot.com/images&image=${imageName}`
      )
      .then(({ data }) => {
        const { image_url } = data
        return image_url
      })
      .catch(err => {
        if (err.response.status === 404) {
          console.error(`Couldn't find ${imageName}`)
          throw { missing: true }
        } else {
          throw err
        }
      })
  }

  const defaultValues = {
    pages,
    dispatch,
    lockPage,
    currentPage,
    fetchAroundPage,
    fetchImage,
    updateCurrentPage,
    currentYear,
    scrollToPage,
    scrollToYear,
    chaptersOpen,
    setChaptersOpen,
    showEdges,
    mounting,
    loading,
    setLoading,
    fetching,
    scrolling,
    setScrolling,
    activeTrack,
    setActiveTrack,
    jumpToPage,
    jumpToYear,
    menuOpen,
    setMenuOpen,
    FETCH_LIMIT,
    NUM_PAGES,
    CHAPTERS,
    STOP_MUSIC,
  }
  return <PageContext.Provider value={defaultValues}>{props.children}</PageContext.Provider>
}

// Hook
export const usePages = () => {
  const context = React.useContext(PageContext)
  if (context === 'undefined')
    throw new Error('`usePages` hook must be used within a `PageProvider` component')
  return context
}
