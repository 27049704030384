import React from 'react'
import tw, { styled } from 'twin.macro'
import { Header } from './components/Header'
import { ThemeProvider } from 'styled-components'
import useTheme from './hooks/useTheme'
import GlobalStyle from './styles/styles'
import { PageProvider } from './hooks/usePages'
import { BrowserRouter } from 'react-router-dom'
import { Navigator } from './components/Navigator'
import { Reader } from './components/Reader'

const App = () => {
  const theme = useTheme()

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <PageProvider>
          <GlobalStyle />
          <Container>
            <Header />
            <Reader />
            <Navigator />
          </Container>
        </PageProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App

const Container = styled.div`
  ${tw`flex flex-col relative items-center w-screen maxHeight[100%]`}
`
