import React, { useEffect, useMemo, useState } from 'react'
import { useOnScreen } from '../hooks/useOnScreen'
import { usePages } from '../hooks/usePages'
import { useScroll } from '../hooks/useScroll'
import tw, { styled } from 'twin.macro'

export const AudioSensor = ({ audio, debug }) => {
  return audio.start ? (
    <StartSensor audio={audio} debug={debug} />
  ) : (
    <StopSensor audio={audio} debug={debug} />
  )
}

const StartSensor = ({ audio, debug }) => {
  const { visible: startVisible, setRef: startRef, scrolling } = useOnScreen({
    rootMargin: '-30% 0% -30% 0%',
  })
  const { scrollingDown } = useScroll()
  const { activeTrack, setActiveTrack, STOP_MUSIC, loading } = usePages()
  const playing = useMemo(() => activeTrack === audio.track, [activeTrack, audio.track])
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    if (!mounted) return setMounted(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!mounted || loading || scrolling) return
    if (startVisible) {
      scrollingDown && !playing && setActiveTrack(audio.track)
    } else {
      !scrollingDown && setActiveTrack(STOP_MUSIC)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startVisible])

  return (
    <Sensor debug={debug} ref={startRef}>
      {JSON.stringify(audio)}
    </Sensor>
  )
}

const StopSensor = ({ audio, debug }) => {
  const { visible: stopVisible, setRef: stopRef, scrolling } = useOnScreen({
    rootMargin: '-30% 0% -30% 0%',
  })
  const { scrollingDown } = useScroll()
  const { activeTrack, setActiveTrack, STOP_MUSIC, loading } = usePages()
  const playing = useMemo(() => activeTrack === audio.track, [activeTrack, audio.track])
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    if (!mounted) return setMounted(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!mounted || loading || scrolling) return
    if (stopVisible) {
      scrollingDown && setActiveTrack(STOP_MUSIC)
    } else {
      !scrollingDown && !playing && setActiveTrack(audio.track)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopVisible])

  return (
    <Sensor debug={debug} ref={stopRef}>
      {JSON.stringify(audio)}
    </Sensor>
  )
}

const Sensor = styled.div`
  ${tw`absolute left-20 right-20 flex pointer-events-none bg-red-400 invisible bg-opacity-40 break-all`}
  ${({ debug }) => debug && tw`visible`}
`
