import { useCallback, useEffect, useState } from 'react'
import { useOnResize } from './useOnResize'

export const useScroll = () => {
  const { fade, header } = useOnResize()
  const [scrollingDown, setScrollingDown] = useState(null)
  const [y, setY] = useState(0)
  let scrolling = false

  function smoothScrollTo(elem, options, duration = 3000, minDuration = 200) {
    setScrollingDown(null)
    return new Promise(resolve => {
      if (!(elem instanceof Element)) throw new TypeError('Argument 1 must be an Element')

      const scrollOptions = Object.assign({ top: 0, left: 0, vertical: true }, options)
      // last known scroll positions
      let startPos_top = elem.scrollTop
      let startPos_left = elem.scrollLeft

      // expected final position
      const maxScroll_top = elem.scrollHeight - elem.clientHeight
      const maxScroll_left = elem.scrollWidth - elem.clientWidth

      const targetPos_top = Math.max(0, Math.min(maxScroll_top, scrollOptions.top))
      const targetPos_left = Math.max(0, Math.min(maxScroll_left, scrollOptions.left))

      let vertical_duration = Math.max(
        (duration * Math.abs(targetPos_top - startPos_top)) / maxScroll_top,
        minDuration
      )
      let horizontal_duration = Math.max(
        (duration * Math.abs(targetPos_left - startPos_left)) / maxScroll_left,
        minDuration
      )

      const changeTop = targetPos_top - startPos_top
      const changeLeft = targetPos_left - startPos_left
      const increment = 20
      let currentTime = 0

      function animateVerticalScroll() {
        currentTime += increment
        var newPos_top = easeInOutCubic(currentTime, startPos_top, changeTop, vertical_duration)
        elem.scrollTop = newPos_top

        if (currentTime < vertical_duration) {
          setTimeout(animateVerticalScroll, increment)
        } else {
          return resolve()
        }
      }
      function animateHorizontalScroll() {
        currentTime += increment
        var newPos_left = easeInOutCubic(
          currentTime,
          startPos_left,
          changeLeft,
          horizontal_duration
        )
        elem.scrollLeft = newPos_left

        if (currentTime < horizontal_duration) {
          setTimeout(animateHorizontalScroll, increment)
        } else {
          return resolve()
        }
      }

      scrollOptions.vertical ? animateVerticalScroll() : animateHorizontalScroll()
    })
  }

  const jumpToPageElement = async ({ page, duration }) => {
    const element = document.getElementById(page)
    const scrollingElement = document.scrollingElement
    if (scrolling || !element) return
    scrolling = true

    const offset = element.offsetTop - header

    try {
      await new Promise(resolve => setTimeout(resolve, duration))
      scrollingElement.scrollTop = offset
      return Promise.resolve()
    } catch (err) {
      console.error({ err })
      return Promise.reject()
    } finally {
      scrolling = false
    }
  }

  const scrollToPageElement = async ({ page, duration }) => {
    const element = document.getElementById(page)
    const scrollingElement = document.scrollingElement
    if (scrolling || !element) return

    const offset = element.offsetTop - header
    scrolling = true

    try {
      await smoothScrollTo(scrollingElement, { top: offset }, duration)
      // all done, lower the flag
      return Promise.resolve()
    } catch (err) {
      console.error('Aborted scroll')
      console.error({ err })
      return Promise.reject()
    } finally {
      scrolling = false
    }
  }

  const jumpToInnerElement = async ({ page, inner, duration = 2000 }) => {
    const pageElement = document.getElementById(page)
    const innerElement = document.getElementById(inner)
    const scrollingElement = document.scrollingElement
    if (scrolling || !pageElement || !innerElement) return

    const offset = pageElement.offsetTop + (innerElement.offsetTop || 0) - header
    scrolling = true

    try {
      await new Promise(resolve => setTimeout(resolve, duration))
      scrollingElement.scrollTop = offset
      return Promise.resolve()
    } catch (err) {
      console.log('Aborted jump')
      console.error({ err })
      return Promise.reject()
    } finally {
      scrolling = false
    }
  }

  const scrollToInnerElement = async ({ page, inner, duration = 2500 }) => {
    const pageElement = document.getElementById(page)
    const pageContainer = document.getElementById(`${page}_container`)
    const innerElement = document.getElementById(inner)
    const scrollingElement = document.scrollingElement
    if (scrolling || !pageElement || !innerElement) return

    let imagesHeight = 0
    let done = false
    const _children = [...pageContainer.children].map(node => {
      if (!done && node.classList.contains('image_container')) {
        imagesHeight += node.getBoundingClientRect().height
      }
      if (node.classList.contains('more_container')) {
        done = true
      }
    })

    // console.log({ imagesHeight })
    const offset = pageElement.offsetTop + imagesHeight + (innerElement.offsetTop || 0) - fade
    scrolling = true

    try {
      await smoothScrollTo(scrollingElement, { top: offset }, duration)
      return Promise.resolve()
    } catch (err) {
      console.error('Aborted scroll')
      console.error({ err })
      return Promise.reject()
    } finally {
      scrolling = false
    }
  }

  const scrollToYear = async ({ scrollElement, yearElement, padding, duration }) => {
    if (scrolling || !yearElement) return
    scrolling = true
    const offset = yearElement.offsetLeft - padding
    try {
      await smoothScrollTo(scrollElement, { left: offset, vertical: false }, duration, 200)
      return Promise.resolve()
    } catch (err) {
      console.error('Aborted scroll')
      console.error({ err })
      return Promise.reject()
    } finally {
      scrolling = false
    }
  }

  const toggleScrollDirection = useCallback(() => {
    let scrollY = window.scrollY
    if (scrollY > y) {
      setScrollingDown(true)
    } else if (scrollY < y) {
      setScrollingDown(false)
    }
    setY(scrollY)
  }, [y])

  useEffect(() => {
    setY(window.scrollY)
    window.addEventListener('scroll', toggleScrollDirection)
    return () => {
      window.removeEventListener('scroll', toggleScrollDirection)
    }
  }, [toggleScrollDirection])

  // // TODO: use this?
  // const rotateScreen = useCallback(() => {
  //   const newHeight = document.scrollingElement.offsetTop
  //   // console.log({ newHeight })
  // }, [])

  // useEffect(() => {
  //   window.addEventListener('orientationchange', rotateScreen)
  //   return () => {
  //     window.removeEventListener('orientationchange', rotateScreen)
  //   }
  // }, [rotateScreen])

  return {
    jumpToPageElement,
    jumpToInnerElement,
    scrollToPageElement,
    scrollToInnerElement,
    scrollToYear,
    scrollingDown,
  }
}

const easeInOutCubic = (t, b, c, d) => {
  if ((t /= d / 2) < 1) return (c / 2) * t * t * t + b
  return (c / 2) * ((t -= 2) * t * t + 2) + b
}
const _easeInOutQuad = (t, b, c, d) => {
  t /= d / 2
  if (t < 1) return (c / 2) * t * t + b
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}
