import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import tw, { styled, css } from 'twin.macro'
import theming from 'styled-theming'
import { usePages } from '../hooks/usePages'
import { AnimatePresence, motion } from 'framer-motion'
import { useOnScreen } from '../hooks/useOnScreen'
import { useScroll } from '../hooks/useScroll'
import AutosizeInput from 'react-input-autosize'
import { DARK_X_CIRCLE, LIGHT_X_CIRCLE } from '../styles/icons'
import { Player } from '@lottiefiles/react-lottie-player'
import light_squiggles from '../assets/light-pattern'
import dark_squiggles from '../assets/dark-pattern'
import { useTheme } from 'styled-components'
import { analytics } from '../firebase'

// *******************************************
// ******************* MODAL *****************
// *******************************************
export const SelectChapter = () => {
  const { chaptersOpen, setChaptersOpen, lockPage, jumpToPage, jumpToYear } = usePages()
  const theme = useTheme()
  const lottieRef = useRef()
  const [yearTransition, setYearTransition] = useState(null)
  const [busy, setBusy] = useState(true)

  // Prevent body scroll events from propagating
  useEffect(() => {
    setYearTransition(null)
    setBusy(true)
    if (chaptersOpen) lockPage(true)
    else lockPage(false)

    return () => {
      lockPage(false)
    }
  }, [chaptersOpen, lockPage])

  const onOpenChapter = async year => {
    if (busy) return
    // Fetch pages and transition
    jumpToYear({ year: year.year, page: year.pageNo })
    setYearTransition(year)
  }

  const onOpenPage = pageNo => {
    if (busy) return
    // Fetch pages and close
    const page = parseInt(pageNo - 1)
    jumpToPage({ page })
    closeModal()
  }

  const closeModal = () => {
    setChaptersOpen(false)
  }

  return (
    <AnimatePresence>
      {chaptersOpen && (
        <motion.div
          tw="fixed w-screen h-screen zIndex[100]"
          className="modal"
          id="modal"
          variants={{
            show: { y: 0 },
            hide: { y: '-100vh' },
          }}
          initial="hide"
          animate="show"
          transition={{
            ease: 'easeInOut',
            duration: 0.5,
          }}
          exit={yearTransition ? { opacity: 0, transition: { duration: 1.5 } } : 'hide'}
        >
          <Fade position="left" id="fade" />
          <SelectYearOrPage
            transition={yearTransition}
            busy={busy}
            setBusy={setBusy}
            onOpenChapter={onOpenChapter}
            onOpenPage={onOpenPage}
          />
          {/* *************** YEAR TRANSITION *************** */}
          <AnimatePresence>
            {yearTransition && (
              <motion.div
                tw="relative w-full h-full flex justify-center items-center"
                transition={{ duration: 3 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                <motion.div
                  tw="w-full h-full opacity-10"
                  animate={{ scale: 1.05 }}
                  transition={{
                    repeat: Infinity,
                    repeatType: 'reverse',
                    repeatDelay: 0,
                    duration: 8,
                  }}
                >
                  <Player
                    autoplay={true}
                    loop={true}
                    controls={false}
                    renderer="svg"
                    ref={lottieRef}
                    rendererSettings={{
                      preserveAspectRatio: 'xMidYMid slice',
                    }}
                    src={theme.mode === 'dark' ? light_squiggles : dark_squiggles}
                    style={{ height: '100vh', width: '100%' }}
                  ></Player>
                </motion.div>

                <TransitionText
                  tw="absolute top[25%]"
                  animate={{
                    y: ['120%', '0%'],
                    opacity: [0, 1],
                    scale: [1, 1.5],
                    transition: { easing: 'easeInOut', duration: 3.5, times: [0, 0.7] },
                  }}
                  onAnimationComplete={closeModal}
                >
                  {yearTransition?.year}
                </TransitionText>
              </motion.div>
            )}
          </AnimatePresence>
          <Fade position="right" />
        </motion.div>
      )}
    </AnimatePresence>
  )
}
const Fade = styled.div`
  ${tw`absolute h-screen top-0 width[20%] xs:width[30%] zIndex[100]`};
  pointer-events: none;
  ${({ position }) =>
    theming('mode', {
      light: css`
        ${position === 'left' ? tw`left-0` : tw`right-0`};
        background-image: linear-gradient(
          90deg,
          rgba(253, 249, 240, ${position === 'left' ? 1 : 0}),
          rgba(253, 249, 240, ${position === 'left' ? 0 : 1})
        );
      `,
      dark: css`
        ${position === 'left' ? tw`left-0` : tw`right-0`};
        background-image: linear-gradient(
          90deg,
          rgba(28, 29, 30, ${position === 'left' ? 1 : 0}),
          rgba(28, 29, 30, ${position === 'left' ? 0 : 1})
        );
      `,
    })};
`
const TransitionText = tw(motion.div)`text-4xl xs:text-5xl md:text-7xl lg:text-8xl xl:text-9xl`

// **********************************************
// ******************* CAROUSEL *****************
// **********************************************
const SelectYearOrPage = ({ transition, busy, setBusy, onOpenChapter, onOpenPage }) => {
  const { CHAPTERS, NUM_PAGES, currentPage, currentYear, setChaptersOpen } = usePages()
  const { scrollToYear } = useScroll()
  const theme = useTheme()
  const [viewportW, setViewportW] = useState(0)
  const [inputValue, setInputValue] = useState(currentPage + 1)
  const [scrollStop, setScrollStop] = useState(false)
  const [resizeKey, setResizeKey] = useState(0)
  const yearWidth = useMemo(
    () =>
      document.getElementById(`yearButton#${CHAPTERS[0]?.year}`)?.getBoundingClientRect().width ??
      240,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [CHAPTERS, viewportW]
  )
  const paddingWidth = useMemo(() => viewportW / 2 - yearWidth / 2, [viewportW, yearWidth])
  const carouselRef = useRef(null)
  const inputRef = useRef(null)

  const onResize = useCallback(entries => {
    setResizeKey(oldKey => oldKey + 1)
    for (let entry of entries) {
      setViewportW(entry.contentRect.width)
    }
  }, [])

  useLayoutEffect(() => {
    const topResizeObserver = new ResizeObserver(entries => onResize(entries))
    topResizeObserver.observe(carouselRef.current)
    return () => topResizeObserver.disconnect()
  }, [onResize])

  const updateInputValue = ({ target }) => {
    const page = target.value
    setInputValue(!page ? '' : page <= 0 ? 1 : page > NUM_PAGES ? NUM_PAGES : page)
  }

  const visitYear = ({ item, visible }) => {
    if (busy) return
    if (visible) {
      // Start transition and go there
      onOpenChapter(item)
      analytics.logEvent('navigate_chapter')
    } else {
      // Scroll to that item
      scrollToYearButton(item?.year)
    }
  }

  const visitPage = e => {
    e.preventDefault()
    analytics.logEvent('navigate_page')
    inputValue && onOpenPage(inputValue)
  }

  const scrollToYearButton = async year => {
    // const parent = child.parentNode
    // const index = Array.prototype.indexOf.call(parent.children, child) - 1 // remove the filler
    if (year) {
      const child = document.getElementById(`yearButton#${year}`)
      try {
        await scrollToYear({
          scrollElement: carouselRef.current,
          yearElement: child,
          padding: paddingWidth,
          duration: 800,
        })
      } catch (err) {
        console.log({ err })
      } finally {
        setBusy(false)
      }
    } else {
      setBusy(false)
    }
  }

  return (
    <Container
      variants={{
        show: { opacity: 1 },
        hide: { opacity: 0 },
      }}
      transition={{ duration: 0.8 }}
      initial="show"
      animate={transition ? 'hide' : 'show'}
      scrollStop={scrollStop}
      onAnimationComplete={() => scrollToYearButton(currentYear)}
    >
      <TopSection>
        <Carousel
          ref={carouselRef}
          id="yearScroll"
          variants={{
            hide: { opacity: 0 },
            show: {
              opacity: 1,
              transition: {
                delayChildren: 0.1,
                staggerChildren: 0.08,
              },
            },
          }}
          initial="hide"
          animate={transition ? 'hide' : 'show'}
        >
          <div style={{ width: paddingWidth }} />
          {CHAPTERS.map((item, i) => (
            <Year
              key={i}
              item={item}
              index={i}
              id={`yearElement#${i}`}
              onClick={visitYear}
              theme={theme}
            />
          ))}
          <div style={{ width: paddingWidth }} />
        </Carousel>
      </TopSection>
      <BottomSection
        variants={{
          hide: { opacity: 0 },
          show: { opacity: 1 },
        }}
        transition={{ duration: 0.2 }}
        initial="show"
        animate={transition ? 'hide' : 'show'}
      >
        <PageSelector>
          <form onSubmit={visitPage} tw="flex flex-col justify-center items-center">
            <InputContainer onClick={() => document.getElementById('pageInput').focus()}>
              <PageInput
                key={resizeKey}
                type="number"
                id="pageInput"
                value={inputValue}
                min="1"
                max={NUM_PAGES}
                ref={inputRef}
                onChange={updateInputValue}
                onBlur={({ target }) => !target.value && setInputValue(1)}
                onMouseOver={() => setScrollStop(true)}
                onMouseLeave={() => setScrollStop(false)}
              />
              <OfText>&nbsp;av&nbsp;</OfText>
              <InputText>{NUM_PAGES}</InputText>
            </InputContainer>
            <PageButton
              inputValue={inputValue}
              variants={{
                hover: { scale: 1.1 },
                tap: { scale: 1.2 },
                hide: { opacity: 0, visibility: 'hidden' },
                show: { opacity: 1, visibility: 'visible' },
              }}
              whileHover="hover"
              whileTap="tap"
              transition={{ duration: 0.2 }}
              animate={inputValue ? 'show' : 'hide'}
              type="submit"
            >
              &nbsp;&nbsp;GÅ TILL SIDA&nbsp;&nbsp;
            </PageButton>
          </form>
        </PageSelector>
        <CloseButton
          variants={{ hover: { rotate: 90, scale: 1.2 }, tap: { rotate: 90, scale: 1.2 } }}
          whileHover="hover"
          whileTap="tap"
          onClick={() => setChaptersOpen(false)}
        >
          {theme.mode === 'dark' ? <LIGHT_X_CIRCLE /> : <DARK_X_CIRCLE />}
        </CloseButton>
      </BottomSection>
    </Container>
  )
}

const Container = styled(motion.div)`
  ${tw`fixed top-0 w-screen h-screen zIndex[80] font-serif font-thin overflow-x-hidden`}
  ${({ scrollStop }) => (scrollStop ? tw`overflow-hidden` : tw`overflow-y-scroll`)}
`

const TopSection = styled(motion.div)`
  ${tw`sticky top-0 flex height[50vh] w-full border-b-2 items-end`}
  ${theming('mode', {
    light: tw`border-gray-500`,
    dark: tw`border-gray-400`,
  })}
`
const BottomSection = tw(
  motion.div
)`sticky top[50vh] flex flex-col height[50vh] items-center justify-evenly`

const Carousel = styled(motion.section)`
  ${tw`flex justify-center font-serif width[max-content] h-auto zIndex[90] overflow-x-scroll overflow-y-hidden`}
  display: -webkit-box;
  display: -moz-box;
  display: -ms-box;
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
  &::-webkit-scrollbar {
    display: none;
  }
`

const PageSelector = tw.div`flex flex-col p-2 items-center`
const PageInput = styled(AutosizeInput)`
  ${tw`cursor-pointer`};
  & > input {
    ${tw`text-right cursor-pointer`};
    outline: none;
    ${theming('mode', {
      light: tw`bg-light`,
      dark: tw`bg-dark`,
    })};
  }
`
const InputText = tw.div`flex text-gray-500`
const OfText = tw(InputText)``
const InputContainer = styled.div`
  ${tw`flex items-end  cursor-pointer text-5xl mb-6 -ml-3`}
  & > ${OfText} {
    ${tw`text-4xl`}
  }
`
const PageButton = styled(motion.button)`
  ${tw`w-min whitespace-nowrap text-center border-b select-none focus:outline-none text-lg`}
  ${theming('mode', {
    light: tw`border-gray-500`,
    dark: tw`border-gray-400`,
  })}
  ${({ inputValue }) =>
    inputValue ? tw`cursor-pointer` : tw`cursor-default opacity-40 border-none`}
`
const CloseButton = tw(motion.div)`flex justify-center items-center cursor-pointer select-none`

// ******************************************
// ******************* YEAR *****************
// ******************************************
const Year = ({ item, index, onClick }) => {
  const { setRef, visible } = useOnScreen({ rootMargin: '0% -50%' })

  return (
    <YearContainer
      id={`yearButton#${item.year}`}
      // Spring bobbing on open
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ type: 'spring', stiffness: 120, mass: 1.2, damping: 12 }}
      onClick={() => onClick({ item, index, visible })}
      ref={setRef}
    >
      <MotionYear
        variants={{
          focus: { y: 0 },
          unfocus: { y: '35%', opacity: 0.3 },
        }}
        initial={{ y: 100 }}
        animate={visible ? 'focus' : 'unfocus'}
        whileHover="hover"
        whileTap="tap"
        transition={{ ease: 'backOut' }}
      >
        <YearText
          variants={{ hover: visible ? { scale: 1.05 } : { y: '-20%' }, tap: { scale: 1.05 } }}
          id={`text-${item.year}`}
        >
          {item.year}
        </YearText>
        <ReadText
          variants={{
            hover: { scale: 1.1 },
            tap: { scale: 1.2 },
          }}
        >
          &nbsp;&nbsp;GÅ TILL ÅR&nbsp;&nbsp;
        </ReadText>
      </MotionYear>
    </YearContainer>
  )
}

const YearContainer = tw(
  motion.div
)`flex flex-col text-center justify-end items-center mr-2 xs:mr-8 rounded-lg overflow-hidden`

const MotionYear = tw(
  motion.div
)`flex flex-col justify-center items-center w-60 xs:w-80 h-40 cursor-pointer`
const YearText = tw(motion.div)`text-5xl xs:text-6xl mb-5 whitespace-nowrap`
const ReadText = styled(motion.div)`
  ${tw`border-b w-min whitespace-nowrap text-lg`}
  ${theming('mode', {
    light: tw`border-gray-500`,
    dark: tw`border-gray-400`,
  })}
`
