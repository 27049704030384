import _ from 'lodash'
export const actionTypes = {
  page: {
    add: 'ADD_PAGE',
    addMany: 'ADD_PAGES',
    fill: 'FILL_PAGES',
    fillPages: 'FILL_ALL',
  },
}

export const pageReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.page.add: {
      const { page } = action
      return _.uniqBy([...state, page], 'pageNo')
    }
    case actionTypes.page.addMany: {
      const { pages } = action
      return _.uniqBy([...state, ...pages], 'pageNo')
    }
    case actionTypes.page.fill: {
      const { pages } = action
      const newState = [...state]
      pages.forEach(page => {
        newState[page.pageNo] = {
          ...page,
          loaded: true,
        }
      })
      return newState
    }
    case actionTypes.page.fillPages: {
      const { pages } = action
      return pages.map((page, i) => ({ pageNo: i, data: [...page], loaded: true }))
    }
    default:
      return state
  }
}
