import React, { useEffect, useState } from 'react'
import theming from 'styled-theming'
import tw, { styled, css } from 'twin.macro'
import theme from 'styled-theming'
import { Button } from '../styles/styles'
import { motion } from 'framer-motion'
import { usePages } from '../hooks/usePages'
import { useOnResize } from '../hooks/useOnResize'

export const MoreComponent = ({ id, children }) => {
  const [expanded, setExpanded] = useState(false)
  const { scrolling } = usePages()
  const { header, fade, footer } = useOnResize()

  useEffect(() => {
    scrolling && setExpanded(false)
  }, [scrolling])

  const onSetExpanded = async () => {
    setExpanded(true)
  }

  return (
    <Container className="more_container">
      <Separator position="top" />
      <div tw="relative flex flex-col w-full" id={id}>
        <Fade
          position="top"
          variants={{
            compact: { height: '0' },
            expand: { height: '5rem' },
          }}
          initial="compact"
          animate={expanded ? 'expand' : 'compact'}
        />
        <TextContainer
          className="more"
          variants={{
            compact: { maxHeight: '20vh', overflowY: 'hidden' },
            expand: {
              minHeight: '20vh',
              maxHeight: `calc(${window.innerHeight - (footer + header + 2 * fade)}px)`,
              overflowY: 'scroll',
              padding: '1rem 0',
            },
          }}
          transition={{ duration: 0.4 }}
          initial="compact"
          animate={expanded && 'expand'}
        >
          {children}
        </TextContainer>

        <Fade
          position="bottom"
          variants={{
            compact: { height: '10rem' },
            expand: { height: '5rem' },
          }}
          initial="compact"
          animate={expanded && 'expand'}
        />
        <Separator position="bottom" />
      </div>

      <motion.div
        animate={expanded && { opacity: 0, height: 0, transition: { duration: 0.4 } }}
        tw="overflow-hidden"
      >
        <ReadMoreButton onClick={onSetExpanded}>{'LÄS MER'}</ReadMoreButton>
      </motion.div>
    </Container>
  )
}
const Container = tw.div`relative flex flex-col justify-center items-center -mx-4 xs:-mx-6 sm:-mx-8 md:-mx-10 lg:-mx-16 xl:-mx-20 2xl:-mx-32`
const TextContainer = styled(motion.div)`
  ${tw`relative flex flex-col mx-4 xs:mx-6 sm:mx-8 md:mx-10 lg:mx-16 xl:mx-20 2xl:mx-32`}
  &::-webkit-scrollbar {
    display: none;
  }
`
const Separator = styled.div`
  ${tw`border w-full`}
  ${({ position }) => (position === 'top' ? tw`border-t-2` : tw`border-b-2`)}
  ${theme('mode', {
    light: tw`border-gray-500`,
    dark: tw`border-gray-400`,
  })}
`

const Fade = styled(motion.div)`
  ${tw`w-full absolute`};
  pointer-events: none;
  ${({ position }) =>
    theming('mode', {
      light: css`
        ${position === 'top'
          ? css`
              ${tw`z-10 top-0`};
              background-image: linear-gradient(0, rgba(253, 249, 240, 0), rgba(253, 249, 240, 1));
            `
          : css`
              ${tw`bottom[3px]`};
              background-image: linear-gradient(0, rgba(253, 249, 240, 1), rgba(253, 249, 240, 0));
            `}
      `,
      dark: css`
        ${position === 'top'
          ? css`
              ${tw`z-10 top-0`};
              background-image: linear-gradient(0, rgba(28, 29, 30, 0), rgba(28, 29, 30, 1));
            `
          : css`
              ${tw`bottom[3px]`};
              background-image: linear-gradient(0, rgba(28, 29, 30, 1), rgba(28, 29, 30, 0));
            `}
      `,
    })};
`

const ReadMoreButton = tw(
  Button
)`text-sm md:text-base border-2 border-t-0 rounded-t-none rounded-b-md py-2`
