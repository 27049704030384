import { useCallback, useLayoutEffect, useState } from 'react'

export const useOnResize = () => {
  const fade = 15
  const [header, setHeader] = useState(56)
  const [footer, setFooter] = useState(100)

  const onResize = useCallback(() => {
    setHeader(document.getElementById('header')?.getBoundingClientRect().height + fade)
    setFooter(document.getElementById('footer')?.getBoundingClientRect().height + fade)
  }, [])

  useLayoutEffect(() => {
    const observer = new ResizeObserver(entries => onResize(entries))
    observer.observe(document.scrollingElement)

    return () => {
      observer.unobserve(document.scrollingElement)
    }
  }, [onResize])

  return {
    fade,
    footer,
    header,
  }
}
