import firebase from 'firebase'
import 'firebase/analytics'

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: 'rolf-780ff.appspot.com',
  messagingSenderId: '228588044345',
  appId: '1:228588044345:web:a4eaa3f596049b5390cde8',
  measurementId: 'G-N7H5BPHEC1',
}
firebase.initializeApp(firebaseConfig)

export const analytics = firebase.analytics()
export const storage = firebase.storage()
export const storageRef = storage.ref()
// Create a child referens
export const imagesRef = storageRef.child('images')
export const videosRef = storageRef.child('videos')
