import React, { useEffect, useState } from 'react'
import tw, { css, styled } from 'twin.macro'
import { AnimatePresence, motion } from 'framer-motion'
import { DARK_MOON, LIGHT_SUN } from '../styles/icons'
import { usePages } from '../hooks/usePages'
import { PageFade } from '../styles/styles'
import { useTheme } from 'styled-components'
import { MenuButton } from './Menu/MenuButton'
import { Menu } from './Menu/Menu'
import { analytics } from '../firebase'

export const Header = () => {
  const { showEdges } = usePages()
  const theme = useTheme()
  const onToggleTheme = () => {
    theme.setTheme(theme.mode === 'dark' ? { ...theme, mode: 'light' } : { ...theme, mode: 'dark' })
  }
  const _onZoomIn = e => {
    e.stopPropagation()

    theme.setTheme(
      theme.textZoom === 'xsmall'
        ? { ...theme, textZoom: 'small' }
        : theme.textZoom === 'small'
        ? { ...theme, textZoom: 'normal' }
        : theme.textZoom === 'normal'
        ? { ...theme, textZoom: 'large' }
        : theme.textZoom === 'large'
        ? { ...theme, textZoom: 'xlarge' }
        : { ...theme, textZoom: 'xxlarge' }
    )
  }
  const _onZoomOut = e => {
    e.stopPropagation()
    theme.setTheme(
      theme.textZoom === 'xxlarge'
        ? { ...theme, textZoom: 'xlarge' }
        : theme.textZoom === 'xlarge'
        ? { ...theme, textZoom: 'large' }
        : theme.textZoom === 'large'
        ? { ...theme, textZoom: 'normal' }
        : theme.textZoom === 'normal'
        ? { ...theme, textZoom: 'small' }
        : { ...theme, textZoom: 'xsmall' }
    )
  }
  const dark = theme.mode === 'dark'
  const [toggleTheme, setToggleTheme] = useState(false)
  const { menuOpen, setMenuOpen } = usePages()

  useEffect(() => {
    const doToggle = async () => {
      setTimeout(() => {
        setToggleTheme(() => false)
        onToggleTheme()
      }, 150)
    }
    toggleTheme && doToggle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleTheme])

  return (
    <>
      <Container
        className="header"
        id="header"
        animate={showEdges ? { y: 0 } : { y: -100 }}
        transition={{ ease: 'easeInOut' }}
      >
        <PageFade
          position="top"
          animate={showEdges ? { y: 0 } : { y: -100 }}
          transition={{ ease: 'easeInOut' }}
        />
        <HeaderContainer>
          <div />
          <div tw="flex mx-2 gap-2 items-center">
            <LightSwitch
              variants={{
                init: {},
                show: {},
                hide: {},
              }}
              onClick={() => !toggleTheme && setToggleTheme(() => true)}
              animate={toggleTheme ? 'hide' : 'init'}
            >
              <AnimatePresence>
                {dark ? (
                  <motion.div
                    variants={{
                      init: { scale: 1 },
                      show: { rotate: 45, scale: 1.2 },
                      hide: { scale: 0, rotate: 180 },
                    }}
                  >
                    <LIGHT_SUN />
                  </motion.div>
                ) : (
                  <motion.div
                    variants={{
                      init: { scale: 1 },
                      show: { scale: 1.2 },
                      hide: { scale: 0, rotate: 180 },
                    }}
                  >
                    <DARK_MOON />
                  </motion.div>
                )}
              </AnimatePresence>
            </LightSwitch>
            <Switch
              as={MenuButton}
              isOpen={menuOpen}
              onClick={() => {
                analytics.logEvent('click_menu')
                setMenuOpen(!menuOpen)
              }}
              strokeWidth="2"
              transition={{ ease: 'easeOut', duration: 0.2 }}
              width="40"
              height="40"
            />
          </div>
        </HeaderContainer>
      </Container>
      <Menu />
    </>
  )
}

const Container = tw(motion.div)`sticky top[-0.1rem] zIndex[80] flex w-full`
const HeaderContainer = tw.div`flex justify-between items-center w-full py-2 px-2 xs:px-10 md:px-20 lg:px-48 xl:px-60`
const _ZoomButtons = tw.div`flex mr-8`
const Switch = styled(motion.div)`
  ${tw`flex p-2 cursor-pointer justify-center items-center minWidth[2.5rem] rounded-md overflow-hidden`}
  ${({ theme }) => css`
    ${theme.mode === 'dark'
      ? tw`xs:hover:bg-gray-800 active:bg-gray-700`
      : tw`xs:hover:bg-gray-200 active:bg-gray-300`};
  `};
  &:hover > * > * {
    transition: transform 150ms ease-in-out;
    ${tw`transform scale-125`}
  }
  & > * > * {
    transition: transform 150ms ease-in-out;
    ${tw`transform scale-100`}
  }
`
const LightSwitch = styled(Switch)`
  &:hover > * > * {
    transition: transform 150ms ease-in-out;
    ${tw`transform[rotate(-25deg)] scale-125`}
  }
  & > * > * {
    transition: transform 150ms ease-in-out;
    ${tw`transform rotate-0 scale-100`}
  }
`
