import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { useTheme } from 'styled-components'
import tw, { css, styled } from 'twin.macro'
import { usePages } from '../../hooks/usePages'
import { DARK_X_CIRCLE, LIGHT_X_CIRCLE } from '../../styles/icons'

export const Menu = () => {
  const { lockPage, menuOpen, setChaptersOpen, setMenuOpen } = usePages()
  const theme = useTheme()

  // Prevent body scroll events from propagating
  useEffect(() => {
    if (menuOpen) lockPage(true)
    else lockPage(false)

    return () => {
      lockPage(false)
    }
  }, [lockPage, menuOpen])

  const visitPage = page => {
    window.location.href = `https://${page}`
  }
  const selectChapter = () => {
    setMenuOpen(false)
    setChaptersOpen(true)
  }

  return (
    <AnimatePresence>
      {menuOpen && (
        <Container
          variants={{
            show: { x: 0 },
            hide: { x: '200vh' },
          }}
          initial="hide"
          animate="show"
          transition={{
            ease: 'easeInOut',
            duration: 0.5,
          }}
          exit="hide"
        >
          <MenuContainer>
            <MenuOption onClick={selectChapter} tw="mb-10">
              Sidnavigering
            </MenuOption>
            <MenuOption onClick={() => visitPage('rolfborjlind.com/appendix')}>Appendix</MenuOption>
            <MenuOption onClick={() => visitPage('rolfborjlind.com/minnen-kusinen')}>
              Minnen - Kusinen
            </MenuOption>
            <MenuOption onClick={() => visitPage('rolfborjlind.com/minnen-den-osynlige')}>
              Minnen - Den osynlige
            </MenuOption>
            <MenuOption onClick={() => visitPage('rolfborjlind.com/minnen-en-vacker-yngling')}>
              Minnen - En vacker yngling
            </MenuOption>
            <CloseContainer>
              <CloseButton
                variants={{ hover: { rotate: 90, scale: 1.2 }, tap: { rotate: 90, scale: 1.2 } }}
                whileHover="hover"
                whileTap="tap"
                onClick={() => setMenuOpen(false)}
              >
                {theme.mode === 'dark' ? <LIGHT_X_CIRCLE /> : <DARK_X_CIRCLE />}
              </CloseButton>
            </CloseContainer>
          </MenuContainer>
        </Container>
      )}
    </AnimatePresence>
  )
}

const Container = styled(motion.div)`
  ${tw`fixed flex flex-col w-screen h-screen zIndex[79] pt-20 overflow-y-scroll user-select[none]`}
  ${({ theme }) =>
    css`
      ${theme.mode === 'dark' ? tw`bg-dark-active text-light` : tw`bg-gray-300 text-dark`}
    `}
`
const MenuContainer = tw.div`flex flex-1 gap-3 xl:gap-4 w-full flex-col items-center justify-center`
const MenuOption = styled.div`
  ${tw`flex justify-start text-2xl md:text-4xl lg:text-5xl rounded-md cursor-pointer w-full md:w-4/5 lg:w-3/5 py-2 px-8`}
  ${({ theme }) =>
    css`
      ${theme.mode === 'dark' ? tw`hover:bg-dark` : tw`hover:bg-gray-200`}
    `}
`
const CloseContainer = tw.div`flex w-full justify-center items-center`
const CloseButton = tw(
  motion.div
)`sm:invisible cursor-pointer select-none absolute bottom-20 mx-auto`
