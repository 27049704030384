import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import { usePages } from '../hooks/usePages'
import { motion } from 'framer-motion'
import { SelectChapter } from './SelectChapter'
import { AudioPlayer } from './AudioPlayer'
import { PageFade } from '../styles/styles'
import { analytics } from '../firebase'

export const Navigator = ({ theme }) => {
  const { NUM_PAGES, currentPage, currentYear, setChaptersOpen, showEdges } = usePages()
  const [displayPage, setDisplayPage] = useState(currentPage)
  const [displayYear, setDisplayYear] = useState(currentYear)

  const onClick = () => {
    analytics.logEvent('click_navigator')
    setChaptersOpen(open => !open)
  }

  useEffect(() => {
    // Todo change these independently for some reason?
    setDisplayPage(currentPage)
    currentYear && setDisplayYear(currentYear)
  }, [currentPage, currentYear])

  return (
    <>
      <SelectChapter theme={theme} />
      <Container
        className="footer"
        id="footer"
        animate={showEdges ? { y: 0 } : { y: 200 }}
        transition={{ ease: 'easeInOut' }}
      >
        <PageFade position="bottom" />
        <FooterContainer>
          <AudioPlayer />

          <YearContainer
            onClick={onClick}
            animate={currentYear ? 'show' : 'hide'}
            variants={{ show: {}, hide: {}, hover: { scale: 1.2 }, tap: { scale: 1.2 } }}
            whileHover="hover"
            whileTap="tap"
            transition={{ duration: 0.1 }}
          >
            <Chapter
              variants={{
                show: { height: 'auto' },
                hide: { height: 0 },
              }}
            >
              <Year>{displayYear}</Year>
            </Chapter>
            <Page>
              {displayPage + 1} av {NUM_PAGES}
            </Page>
          </YearContainer>
        </FooterContainer>
      </Container>
    </>
  )
}

const Container = tw(motion.div)`sticky bottom[-0.1rem] zIndex[70] flex font-serif 
w-full select-none py-2`
const FooterContainer = tw.div`flex w-full justify-between items-center py-1 px-2 xs:px-10 md:px-20 lg:px-48 xl:px-60`
const YearContainer = tw(motion.div)`flex flex-col justify-end items-center
h-full minWidth[150px] w-auto mx-3 cursor-pointer rounded-lg overflow-hidden`
const Chapter = tw(motion.div)`font-medium overflow-hidden`
const Year = tw.div`mb-1 text-2xl xs:text-3xl whitespace-nowrap`
const Page = tw(motion.div)`mb-2 text-center text-base md:text-lg whitespace-nowrap`
